const Config = {
  app: {
    webaname: 'RSH Hospital',
    company: 'RSH Hospital',
    copyright: () => {
      let date = new Date()
      if (date.getFullYear() > 2022) {
        return '2022-' + date.getFullYear()
      } else {
        return '2022'
      }
    }
  },

  encrypt_key: "#@PI2022!!",
  datePickerOnlyDate: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  mr: {
    StatusOptions: [{
        text: 'Aktif',
        value: 'Y'
      },
      {
        text: 'Nonaktif',
        value: 'N'
      },
    ],
    StatusMenular: [{
        text: 'Menular',
        value: 'Y'
      },
      {
        text: 'Tidak Menular',
        value: 'N'
      },
    ],
    StatusPanggil: [{
        text: 'Hadir',
        value: 'Y'
      },
      {
        text: 'Belum Hadir',
        value: 'B'
      },
      {
        text: 'Tidak Hadir',
        value: 'N'
      },
    ],

    StatusPanggilTanpaHadir: [
      {
        text: 'Belum Hadir',
        value: 'B'
      },
      {
        text: 'Tidak Hadir',
        value: 'N'
      },
    ],

    StatusPanggil2: [{
        text: 'Hadir',
        value: 'Y'
      },
      {
        text: 'Tidak Hadir',
        value: 'N'
      },
    ],
    StatusRegular: [{
        text: 'Regular',
        value: 'R'
      },
      {
        text: 'Iregular',
        value: 'I'
      },
    ],
    StatusPernikahan: [{
        text: 'Belum Kawin',
        value: 1
      },
      {
        text: 'Kawin',
        value: 2
      },
      {
        text: 'Cerai Hidup',
        value: 3
      },
      {
        text: 'Cerai Mati',
        value: 4
      },
    ],
    StatusKelamin: [{
      text: 'LAKI-LAKI',
      value: 1
    }, {
      text: 'PEREMPUAN',
      value: 2
    }, {
      text: 'TIDAK DIKETAHUI',
      value: "0"
    }, {
      text: 'TIDAK DAPAT DITENTUKAN',
      value: 3
    }, {
      text: 'TIDAK MENGISI',
      value: 4
    }, ],


    KategoriRadiologi: [{
        text: 'Radiologi Tanpa Kontras',
        value: 1
      },
      {
        text: 'Ultrasonografi (USG)',
        value: 2
      },
      {
        text: 'Radiologi dengan Kontras',
        value: 3
      },
      {
        text: 'Pemeriksaan CT-SCAN',
        value: 4
      }
    ],
    KategoriFisioterapi: [{
        text: 'Elektroterapi',
        value: 1
      },
      {
        text: 'Mekanoterapi',
        value: 2
      },
      {
        text: 'Latihan-Latihan',
        value: 3
      },
    ],
    KategoriLab: [{
        text: 'Hematologi',
        value: 'Hematologi'
      },
      {
        text: 'Kimia',
        value: 'Kimia'
      },
      {
        text: 'Imuno Serologi',
        value: 'Imuno Serologi'
      },
      {
        text: 'Urinalisis',
        value: 'Urinalisis'
      },
      {
        text: 'Analisa Faeces',
        value: 'Analisa Faeces'
      },
      {
        text: 'Endokrinologi',
        value: 'Endokrinologi'
      },
      {
        text: 'Osteoporosis',
        value: 'Osteoporosis'
      },

      {
        text: 'Analisa KLINIK RUTIN',
        value: 'Analisa KLINIK RUTIN'
      },

      {
        text: 'Faeces',
        value: 'Faeces'
      },

      {
        text: 'Faeces & Parasit',
        value: 'Faeces & Parasit'
      },

      {
        text: 'Mikrobiologi',
        value: 'Mikrobiologi'
      },
      
      {
        text: 'Molekuler Diagnostik',
        value: 'Molekuler Diagnostik'
      },

      {
        text: 'Patologi Anatomi',
        value: 'Patologi Anatomi'
      },

      {
        text: 'Lain-lain',
        value: 'Lain-lain'
      },
    ],
    gender: [
      {
        text: 'Laki-Laki',
        value: 'P'
      },
      {
        text: 'Perempuan',
        value: 'W'
      },
    ],
    genderNum: [
      {
        text: 'Laki-Laki',
        value: 1
      },
      {
        text: 'Perempuan',
        value: 2
      },
    ],
    genderText: [
      {
        text: 'Laki-Laki',
        value: 'Laki-Laki'
      },
      {
        text: 'Perempuan',
        value: 'Perempuan'
      },
    ],
    yesNoOptKeluhan: [{
        text: 'Ada Keluhan',
        value: 'Y'
      },
      {
        text: 'Tidak Ada Keluhan',
        value: 'N'
      },
    ],
    yesNoOpt: [{
        text: 'Ya',
        value: 'Y'
      },
      {
        text: 'Tidak Ada',
        value: 'N'
      },
    ],
    yesNoOptV2: [{
        text: 'Ya',
        value: 'Y'
      },
      {
        text: 'Tidak',
        value: 'N'
      },
    ],
    yesNoOptV4: [
      {
        text: 'Tidak',
        value: 'N'
      },
      {
        text: 'Ya',
        value: 'Y'
      },
    ],
    yesNoOptV5: [
      {
        text: 'Tidak Ada',
        value: 'N'
      },
      {
        text: 'Ada',
        value: 'Y'
      },
    ],
    yesNoOptV6: [
      {
        text: 'Tidak',
        value: 'N'
      },
      {
        text: 'Ada',
        value: 'Y'
      },
    ],
    yesNoOptV3: [{
        text: 'Ya',
        value: 1
      },
      {
        text: 'Tidak',
        value: 0
      },
    ],
    golDarah: [{
        text: 'A',
        value: 'A'
      },
      {
        text: 'B',
        value: 'B'
      },
      {
        text: 'O',
        value: 'O'
      },
      {
        text: 'AB',
        value: 'AB'
      },
      {
        text: 'Tidak Tahu',
        value: 'Tidak Tahu'
      }
    ],
    baikNoOpt: [{
        text: 'Baik',
        value: 'B'
      },
      {
        text: 'Tidak Baik',
        value: 'TB'
      },
    ],
    psikologiPot: [{
        text: 'Tidak ada kelainan',
        value: 1
      },
      {
        text: 'Cemas',
        value: 2
      },
      {
        text: 'Takut',
        value: 3
      },
      {
        text: 'Marah',
        value: 4
      },
      {
        text: 'Sedih',
        value: 5
      },
      {
        text: 'Lain-lain',
        value: 6
      },
    ],
    adlStatus: [{
        text: 'Mandiri',
        value: 'MANDIRI'
      },
      {
        text: 'Dibantu',
        value: 'DIBANTU'
      },
    ],
    skalaNyeri: [{
        text: '0',
        value: 0,
        class: 'psr-blue',
      },
      {
        text: '1',
        value: 1,
        class: 'psr-green',
      },
      {
        text: '2',
        value: 2,
        class: 'psr-green',
      },
      {
        text: '3',
        value: 3,
        class: 'psr-light-green',
      },
      {
        text: '4',
        value: 4,
        class: 'psr-light-green',
      },
      {
        text: '5',
        value: 5,
        class: 'psr-yellow',
      },
      {
        text: '6',
        value: 6,
        class: 'psr-yellow',
      },
      {
        text: '7',
        value: 7,
        class: 'psr-orange',
      },
      {
        text: '8',
        value: 8,
        class: 'psr-orange',
      },
      {
        text: '9',
        value: 9,
        class: 'psr-red',
      },
      {
        text: '10',
        value: 10,
        class: 'psr-red',
      },
    ],
    diagnosaKhusus: [{
        text: 'Tidak Ada',
        value: 'Tidak Ada'
      },
      {
        text: 'Diabetes Mellitus',
        value: 'Diabetes Mellitus'
      },
      {
        text: 'Hipertensi',
        value: 'Hipertensi'
      },
      {
        text: 'Cronical Renal Failure',
        value: 'Cronical Renal Failure'
      },
      {
        text: 'Infeksi Kronis',
        value: 'Infeksi Kronis'
      },
      {
        text: 'Malnutrisi',
        value: 'Malnutrisi'
      },
      {
        text: 'Obesitas',
        value: 'Obesitas'
      },
      {
        text: 'Lschemic Heart Disease',
        value: 'Lschemic Heart Disease'
      },
      {
        text: 'Cences',
        value: 'Cences'
      },
      {
        text: 'Lainnya',
        value: 'L'
      },
    ],
    kriteriaHasil: [{
        text: '1',
        value: 1
      },
      {
        text: '2',
        value: 2
      },
      {
        text: '3',
        value: 3
      },
      {
        text: '4',
        value: 4
      },
      {
        text: '5',
        value: 5
      }
    ],
    configStatus: [{
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'DALAM ANTRIAN',
        value: 'QUEUE'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'DALAM TINDAKAN',
        value: 'ACTION'
      },
      {
        text: 'PASIEN RAWAT INAP',
        value: 'PASIEN_INAP'
      },
      {
        text: 'VOID',
        value: 'VOID'
      },
      {
        text: 'SELESAI',
        value: 'DONE'
      }
    ],
    configStatusTidakHadir: [{
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'TIDAK HADIR',
        value: 'ABSEN'
      },
      {
        text: 'VOID',
        value: 'VOID'
      }
    ],
    configStatusFarmasi: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusBilling: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusKasir: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'PENDING',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],
    configStatusRadiologiLab: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'DALAM TINDAKAN',
        value: 'N'
      },
      {
        text: 'SELESAI TINDAKAN',
        value: 'Y'
      }
    ],
    mCaraPulang: [{
        text: 'Pulang',
        value: 'P'
      },
      {
        text: 'Kontrol',
        value: 'K'
      },
      {
        text: 'Rujuk',
        value: 'RU'
      },
      {
        text: 'Rawat Inap',
        value: 'RI'
      }
    ],
    configStatusLokalis: [{
        text: 'Anatomi Badan',
        value: 'body',
        image: 'img/anatomy-male.png',
        image2: 'img/anatomy-female.png',
      },
      {
        text: 'Anatomi Mata',
        value: 'eye',
        image: 'img/anatomy-collection/eye-anatomy.png',
        image2: 'img/anatomy-collection/eye-anatomy.png',
      },
      {
        text: 'Anatomi Mata Depan',
        value: 'front-eye',
        image: 'img/anatomy-collection/front-eye-anatomy.png',
        image2: 'img/anatomy-collection/front-eye-anatomy.png',
      },
      {
        text: 'Odontogram',
        value: 'odontogram',
        image: 'img/anatomy-collection/odontogram.png',
        image2: 'img/anatomy-collection/odontogram.png',
      },
      {
        text: 'Anatomi Syaraf',
        value: 'nervousSystem',
        image: 'img/anatomy-collection/nervous-anatomy.png',
        image2: 'img/anatomy-collection/nervous-anatomy.png',
      },
      {
        text: 'Anatomi Jantung',
        value: 'heart',
        image: 'img/anatomy-collection/heart-anatomy.png',
        image2: 'img/anatomy-collection/heart-anatomy.png',
      },
      {
        text: 'Anatomi Telinga',
        value: 'ear',
        image: 'img/anatomy-collection/ear-anatomy.png',
        image2: 'img/anatomy-collection/ear-anatomy.png',
      },
      {
        text: 'Anatomi Hidung',
        value: 'nose',
        image: 'img/anatomy-collection/nose-anatomy.png',
        image2: 'img/anatomy-collection/nose-anatomy.png',
      },
      {
        text: 'Anatomi Tenggorokan',
        value: 'throat',
        image: 'img/anatomy-collection/pharynx-anatomy.png',
        image2: 'img/anatomy-collection/pharynx-anatomy.png',
      },
      {
        text: 'Anatomi Paru',
        value: 'lung',
        image: 'img/anatomy-collection/lung-anatomy.png',
        image2: 'img/anatomy-collection/lung-anatomy.png',
      },
      {
        text: 'Anatomi Sendi',
        value: 'joint',
        image: 'img/anatomy-collection/joint-anatomy.png',
        image2: 'img/anatomy-collection/joint-anatomy.png',
      },
    ],
    isMovePoli: [{
        text: 'Ya, Pindah Poli',
        value: 'Y'
      },
      {
        text: 'Tidak',
        value: 'N'
      },
    ],
    mKesadaran: [{
        text: 'Composmentris (GCS 14 - 15)',
        value: 1,
      },
      {
        text: 'Apatis (GCS 12 - 13)',
        value: 2,
      },
      {
        text: 'Somenolen (GCS 10 - 11)',
        value: 3,
      },
      {
        text: 'Delirium (GCS 7 - 9)',
        value: 4,
      },
      {
        text: 'Soporo (GCS 4 - 6)',
        value: 5,
      },
      {
        text: 'Coma (GCS 1 - 3)',
        value: 6,
      },
    ],
    keselamatanPasienA: [{
        text: 'Tidak seimbang/sempoyongan/limbung',
        value: 'Tidak seimbang/sempoyongan/limbung'
      },
      {
        text: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)',
        value: 'Jalan dengan menggunakan alat bantu (kruk, tripot, kursi roda, orang lain)'
      },
    ],
    keselamatanPasienB: [{
      text: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk',
      value: 'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja ata benda lain sebagai penopang saat akan duduk'
    }],

    jenisInsiden: [{
        text: 'KNC',
        value: 1
      },
      {
        text: 'KTD',
        value: 2
      },
      {
        text: 'KTC',
        value: 3
      },
      {
        text: 'KPC',
        value: 4
      },
      {
        text: 'Sentinel',
        value: 5
      },
    ],
    mReportInsiden: [{
        text: 'Karyawan',
        value: 'Karyawan'
      },
      {
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Keluarga / Pendamping pasien',
        value: 'Keluarga / Pendamping pasien'
      },
      {
        text: 'Pengunjung',
        value: 'Pengunjung'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    mOccure: [{
        text: 'Pasien',
        value: 'Pasien'
      },
      {
        text: 'Lain-Lain',
        value: 'L'
      },
    ],
    insidenPasien: [{
        text: 'Pasien Rawat Inap',
        value: 'Pasien Rawat Inap'
      },
      {
        text: 'Pasien Rawat Jalan',
        value: 'Pasien Rawat Jalan'
      },
      {
        text: 'Pasien UGD',
        value: 'Pasien UGD'
      },
      {
        text: 'Lain-lain',
        value: 'L'
      },
    ],
    akibatInsidenPadaPasien: [{
      text: 'Kematian',
      value: 'Kematian'
    }, {
      text: 'Cedera Irreversibel / Cedera Berat',
      value: 'Cedera Irreversibel / Cedera Berat'
    }, {
      text: 'Cedera Reversibel / Cedera Sedang',
      value: 'Cedera Reversibel / Cedera Sedang'
    }, {
      text: 'Cedera Ringan',
      value: 'Cedera Ringan'
    }, {
      text: 'Tidak ada cedera',
      value: 'Tidak ada cedera'
    }],

    tindakanOleh: [{
      text: 'Tim',
      value: 'Tim'
    }, {
      text: 'Dokter',
      value: 'Dokter'
    }, {
      text: 'Perawat',
      value: 'Perawat'
    }, {
      text: 'Petugas Lainnya',
      value: 'Petugas Lainnya'
    }],

    probabilitasOpt: [{
      text: 'Sangat Jarang (>5 tahun/kali)',
      value: 'Sangat Jarang (>5 tahun/kali)'
    }, {
      text: 'Jarang (>2-5 tahun/kali)',
      value: 'Jarang (>2-5 tahun/kali)'
    }, {
      text: 'Mungkin (1-2 tahun/ kali)',
      value: 'Mungkin (1-2 tahun/ kali)'
    }, {
      text: 'Sering (beberapa kali/tahun)',
      value: 'Sering (beberapa kali/tahun)'
    }, {
      text: 'Sangat sering (tiap minggu/ bulan)',
      value: 'Sangat sering (tiap minggu/ bulan)'
    }],

    gradingOpt: [{
      text: 'BIRU',
      value: 'BIRU'
    }, {
      text: 'HIJAU',
      value: 'HIJAU'
    }, {
      text: 'KUNING',
      value: 'KUNING'
    }, {
      text: 'MERAH',
      value: 'MERAH'
    }],

    configResep: [
      {
        text: 'Obat',
        value: 1
      },
      {
        text: 'Alat Kesehatan',
        value: 2
      },
      {
        text: 'Racikan',
        value: 3
      },
      {
        text: 'Paket Alkes',
        value: 4
      },
      {
        text: 'Infus',
        value: 5
      },
    ],
    configResepAlkes: [
      {
        text: 'Alat Kesehatan',
        value: 2
      },
      {
        text: 'Paket Alkes',
        value: 4
      },
      {
        text: 'Infus',
        value: 5
      },
    ],
    configResepRanap: [
      {
        text: 'Obat',
        value: 1
      },
      {
        text: 'Alat Kesehatan',
        value: 2
      },
      {
        text: 'Racikan',
        value: 3
      },
      {
        text: 'Infus',
        value: 5
      },
    ],
    configResepFisio: [
      {
        text: 'Obat',
        value: 1
      },
      {
        text: 'Alat Kesehatan',
        value: 2
      },
      {
        text: 'Racikan',
        value: 3
      },
      {
        text: 'Paket Alkes',
        value: 4
      },
    ],
    configResepPerawatRanap: [{
        text: 'Alat Kesehatan',
        value: 2
      },
      {
        text: 'Infus',
        value: 5
      },
    ],

    configStatusRM: [{
      text: 'Semua Status',
      value: 'ALL'
    }, {
      text: 'Verifikasi Rekam Medis',
      value: 6
    }, {
      text: 'Verifikasi Upla',
      value: 8
    }, {
      text: 'Selesai',
      value: 7
    }, ],

    configStatusUpla: [{
      text: 'Semua Status',
      value: 'ALL'
    }, {
      text: 'Verifikasi Upla',
      value: 8
    }, {
      text: 'Selesai',
      value: 7
    }, ],


    configStatusRMUGD: [{
      text: 'Semua Status',
      value: 'ALL'
    }, {
      text: 'Verifikasi Rekam Medis',
      value: 4
    }, {
      text: 'Verifikasi Upla',
      value: 5
    }, {
      text: 'Selesai',
      value: 6
    }, ],

    configStatusUplaUGD: [{
      text: 'Semua Status',
      value: 'ALL'
    }, {
      text: 'Verifikasi Upla',
      value: 5
    }, {
      text: 'Selesai',
      value: 6
    }],


    configTindakLanjut: [{
        text: 'Edukasi',
        value: 'Edukasi'
      },
      {
        text: 'Kontrol',
        value: 'Kontrol'
      },
      {
        text: 'Rawat Inap',
        value: 'Rawat Inap'
      },
    ],

    configPrioritasPemeriksaan: [{
        text: 'CITO',
        value: 1
      },
      {
        text: 'Non-CITO',
        value: 2
      },
    ],

    configAnamnesa: [{
        text: 'Pasien Sendiri',
        value: 1
      },
      {
        text: 'Orang Lain',
        value: 2
      },
    ],

    configNilaiNyeri: [{
        text: 'Tidak',
        value: 1
      },
      {
        text: 'Ya',
        value: 2
      },
      {
        text: 'Sulit Dinilai',
        value: 3
      },
    ],

    configLamaNyeri: [{
        text: '> 3 bulan(Kronik)',
        value: 1
      },
      {
        text: '< 3 bulan (Akut)',
        value: 2
      },
      {
        text: 'Sulit Dinilai',
        value: 3
      },
    ],


    statusGizi: [{
        text: 'Gizi Kurang/Buruk',
        value: 'Gizi Kurang/Buruk'
      },
      {
        text: 'Gizi Cukup',
        value: 'Gizi Cukup'
      },
      {
        text: 'Gizi Lebih',
        value: 'Gizi Lebih'
      },
      {
        text: 'Lainnya',
        value: 'L'
      },
    ],

    filterTypeLaporan: [{
        text: 'SEMUA KATEGORI',
        value: 'ALL'
      },
      {
        text: 'REKAM MEDIS',
        value: 'RM'
      },
      {
        text: 'SPLPA',
        value: 'SPLPA'
      },
      {
        text: 'LAINNYA',
        value: 'L'
      },
    ],

    statusNyeri: [{
        text: 'Tidak ada Nyeri',
        value: 'Tidak ada Nyeri'
      },
      {
        text: 'Tajam',
        value: 'Tajam'
      },
      {
        text: 'Seperti ditarik',
        value: 'Seperti ditarik'
      },
      {
        text: 'Seperti ditusuk',
        value: 'Seperti ditusuk'
      },
      {
        text: 'Seperti dibakar',
        value: 'Seperti dibakar'
      },
      {
        text: 'Seperti ditikam',
        value: 'Seperti ditikam'
      },
      {
        text: 'Nyeri tumpul',
        value: 'Nyeri tumpul'
      },
      {
        text: 'Seperti dipukul',
        value: 'Seperti dipukul'
      },
      {
        text: 'Seperti berdenyut',
        value: 'Seperti berdenyut'
      },
      {
        text: 'Seperti kram',
        value: 'Seperti kram'
      },
      {
        text: 'Sulit Dinilai',
        value: 'Sulit Dinilai'
      },
    ],

    kompres: [{
        text: 'Hangat',
        value: 'Hangat'
      },
      {
        text: 'Dingin',
        value: 'Dingin'
      },
    ],

    aktifitas: [{
        text: 'Dikurangi',
        value: 'Dikurangi'
      },
      {
        text: 'Ditambah',
        value: 'Ditambah'
      },
    ],

    statusDirujuk: [{
        text: 'Klinik Gizi',
        value: 'Klinik Gizi'
      },
      {
        text: 'Tim Nyeri',
        value: 'Tim Nyeri'
      },
      {
        text: 'Edukasi',
        value: 'Edukasi'
      },
      {
        text: 'Lainnya',
        value: 'L'
      },
    ],

    statusElektroterapi: [{
        text: 'Short Wave Diaterm / UKG',
        value: 'Short Wave Diaterm / UKG'
      },
      {
        text: 'Stimulasi (Faradik/ Galvanik)',
        value: 'Stimulasi (Faradik/ Galvanik)'
      },
      {
        text: 'Ultra Sonik',
        value: 'Ultra Sonik'
      }
    ],

    statusMekanoTerapi: [{
        text: 'Traksi Cervical / Lumbal',
        value: 'Traksi Cervical / Lumbal'
      },
      {
        text: 'Massage',
        value: 'Massage'
      },
      {
        text: 'Exercise Terapi',
        value: 'Exercise Terapi'
      }
    ],

    statusLatihan: [{
        text: 'Penguatan Otot',
        value: 'Penguatan Otot'
      },
      {
        text: 'Koordinasi Gerak',
        value: 'Koordinasi Gerak'
      },
      {
        text: 'Breathing Exersice',
        value: 'Breathing Exersice'
      },
      {
        text: 'Pergerakan Sendi',
        value: 'Pergerakan Sendi'
      },
      {
        text: 'Latihan Jalan',
        value: 'Latihan Jalan'
      },
      {
        text: 'Latihan pra dan pasca bedah',
        value: 'Latihan pra dan pasca bedah'
      },
    ],
    kategoriAsupan: [{
        text: 'Kurang',
        value: 'Kurang'
      },
      {
        text: 'Baik',
        value: 'Baik'
      },
      {
        text: 'Lebih',
        value: 'Lebih'
      }
    ],
    kategoriTindakan: [{
        text: 'Elektroterapi',
        value: 'E'
      },
      {
        text: 'Mekanoterapi',
        value: 'M'
      },
      {
        text: 'Latihan-latihan',
        value: 'L'
      }
    ],

    rencanaTindakan: [{
        text: 'Diathermi',
        value: 'Diathermi'
      },
      {
        text: 'Stimulasi (Tens)',
        value: 'Stimulasi (Tens)'
      },
      {
        text: 'Traksi Lumbal (TL)',
        value: 'Traksi Lumbal (TL)'
      },
      {
        text: 'UltraSound',
        value: 'UltraSound'
      },
      {
        text: 'Infra Red (IR)',
        value: 'Infra Red (IR)'
      },
      {
        text: 'Traksi Cervical (TC)',
        value: 'Traksi Cervical (TC)'
      },
      {
        text: 'Exercise',
        value: 'Exercise'
      },
    ],
    typeSatuan: [{
        text: 'BUNGKUS',
        value: 'BUNGKUS'
      },
      {
        text: 'KAPSUL',
        value: 'KAPSUL'
      },
      {
        text: 'BOTOL',
        value: 'BOTOL'
      },
      {
        text: 'TUBE',
        value: 'TUBE'
      },
      {
        text: 'POT',
        value: 'POT'
      },
    ],
    rentangUmur: [{
        text: 'SEMUA RENTANG UMUR',
        value: 'ALL'
      },
      {
        text: 'BAYI',
        value: 'BAYI'
      },
      {
        text: 'ANAK-ANAK',
        value: 'ANAK-ANAK'
      },
      {
        text: 'DEWASA',
        value: 'DEWASA'
      },
      {
        text: 'LANSIA',
        value: 'LANSIA'
      },
    ],
    jenisKelamin: [{
        text: 'SEMUA JENIS KELAMIN',
        value: 'ALL'
      },
      {
        text: 'LAKI-LAKI',
        value: 1
      },
      {
        text: 'PEREMPUAN',
        value: 2
      },
      {
        text: 'TIDAK DIKETAHUI',
        value: 0
      },
      {
        text: 'TIDAK DAPAT DITENTUKAN',
        value: 3
      },
      {
        text: 'TIDAK MENGISI',
        value: 4
      },
    ],
    statusPasien: [{
        text: 'SEMUA STATUS',
        value: 'ALL'
      },
      {
        text: 'PASIEN LAMA',
        value: 'Y'
      },
      {
        text: 'PASIEN BARU',
        value: 'N'
      },
    ],
    isPerawatOrDokter: [{
      text: 'Perawat',
      value: 'Perawat'
    }, {
      text: 'Dokter',
      value: 'Dokter'
    }, {
      text: 'Bidan',
      value: 'Bidan'
    }],
    AlasanIzin: [{
        text: 'Sakit',
        value: 'Sakit'
      },
      {
        text: 'Hamil',
        value: 'Hamil'
      },
    ],
    KeperluanIzin: [{
      text: 'Istirahat',
      value: 'Istirahat'
    }, {
      text: 'Cuti Hamil',
      value: 'Cuti Hamil'
    }, ],
    configVitalSign: [{
        'value': 'apod_vs_kepala',
        'label': 'Kepala',
        'isOpen': false
      },
      {
        'value': 'apod_vs_mata',
        'label': 'Mata',
        'isOpen': false
      },
      {
        'value': 'apod_vs_hidung',
        'label': 'Hidung',
        'isOpen': false
      },
      {
        'value': 'apod_vs_rambut',
        'label': 'Rambut',
        'isOpen': false
      },
      {
        'value': 'apod_vs_bibir',
        'label': 'Bibir',
        'isOpen': false
      },
      {
        'value': 'apod_vs_gigi',
        'label': 'Gigi',
        'isOpen': false
      },
      {
        'value': 'apod_vs_lidah',
        'label': 'Lidah',
        'isOpen': false
      },
      {
        'value': 'apod_vs_langit',
        'label': 'Langit',
        'isOpen': false
      },
      {
        'value': 'apod_vs_leher',
        'label': 'Leher',
        'isOpen': false
      },
      {
        'value': 'apod_vs_tenggorokan',
        'label': 'Tenggorokan',
        'isOpen': false
      },
      {
        'value': 'apod_vs_tonsil',
        'label': 'Tonsil',
        'isOpen': false
      },
      {
        'value': 'apod_vs_dada',
        'label': 'Dada',
        'isOpen': false
      },
      {
        'value': 'apod_vs_payudara',
        'label': 'Payudara',
        'isOpen': false
      },
      {
        'value': 'apod_vs_punggung',
        'label': 'Punggung',
        'isOpen': false
      },
      {
        'value': 'apod_vs_perut',
        'label': 'Perut',
        'isOpen': false
      },
      {
        'value': 'apod_vs_genital',
        'label': 'Genital',
        'isOpen': false
      },
      {
        'value': 'apod_vs_anus',
        'label': 'Anus',
        'isOpen': false
      },
      {
        'value': 'apod_vs_lengan_atas',
        'label': 'Lengan Atas',
        'isOpen': false
      },
      {
        'value': 'apod_vs_lengan_bawah',
        'label': 'Lengan Bawah',
        'isOpen': false
      },
      {
        'value': 'apod_vs_jari_tangan',
        'label': 'Jari Tangan',
        'isOpen': false
      },
      {
        'value': 'apod_vs_kuku',
        'label': 'Kuku',
        'isOpen': false
      },
      {
        'value': 'apod_vs_sendi_tangan',
        'label': 'Sendi Tangan',
        'isOpen': false
      },
      {
        'value': 'apod_vs_tungkai_atas',
        'label': 'Tungkai Atas',
        'isOpen': false
      },
      {
        'value': 'apod_vs_tungkai_bawah',
        'label': 'Tungkai Bawah',
        'isOpen': false
      },
      {
        'value': 'apod_vs_jari_kaki',
        'label': 'Jari Kaki',
        'isOpen': false
      },
      {
        'value': 'apod_vs_kuku_kaki',
        'label': 'Kuku Kaki',
        'isOpen': false
      },
      {
        'value': 'apod_vs_persendian_kaki',
        'label': 'Persendian Kaki',
        'isOpen': false
      }
    ],
    configVitalSignRanap: [
      {
        'value': 'arankaa_vs_kepala',
        'label': 'Kepala',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_mata',
        'label': 'Mata',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_hidung',
        'label': 'Hidung',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_rambut',
        'label': 'Rambut',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_bibir',
        'label': 'Bibir',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_gigi',
        'label': 'Gigi Geligi',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_lidah',
        'label': 'Lidah',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_langit',
        'label': 'Langit - Langit',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_leher',
        'label': 'Leher',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_tenggorokan',
        'label': 'Tenggorokan',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_tonsil',
        'label': 'Tonsil',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_dada',
        'label': 'Dada',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_payudara',
        'label': 'Payudara',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_punggung',
        'label': 'Punggung',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_perut',
        'label': 'Perut',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_genital',
        'label': 'Genital',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_anus',
        'label': 'Anus/Dubur',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_lengan_atas',
        'label': 'Lengan Atas',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_lengan_bawah',
        'label': 'Lengan Bawah',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_jari_tangan',
        'label': 'Jari Tangan',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_kuku',
        'label': 'Kuku Tangan',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_sendi_tangan',
        'label': 'Pesendian Tangan',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_tungkai_atas',
        'label': 'Tungkai Atas',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_tungkai_bawah',
        'label': 'Tungkai Bawah',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_jari_kaki',
        'label': 'Jari Kaki',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_kuku_kaki',
        'label': 'Kuku Kaki',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_persendian_kaki',
        'label': 'Persendian Kaki',
        'isOpen': false
      }, {
        'value': 'arankaa_vs_saraf',
        'label': 'Saraf',
        'isOpen': false
      }
    ],
    jenisAlergi: [{
        text: 'Obat',
        value: 'Obat'
      },
      {
        text: 'Makanan',
        value: 'Makanan'
      },
      {
        text: 'Udara',
        value: 'Udara'
      },
      {
        text: 'Lainnya',
        value: 'Lainnya'
      },
    ],


    // baru
    statusKewarganegaraan: [{
        text: 'WNA',
        value: 'WNA'
      },
      {
        text: 'WNI',
        value: 'WNI'
      },
    ],
    statusIzin: [{
      text: 'Mengijinkan',
      value: 'Y'
    }, ],
    statusPemeriksaan: [{
        text: 'Semua',
        value: 'ALL'
      },
      {
        text: 'Poliklinik',
        value: 'Poli'
      },
      {
        text: 'Penunjang',
        value: 'Penunjang'
      },
    ],
    statusRujukan: [{
        text: 'Menunggu',
        value: 'Q'
      },
      {
        text: 'Selesai',
        value: 'D'
      }
    ],
    tipeRujukan: [{
        text: 'Rujuk Internal',
        value: 'RI'
      },
      {
        text: 'Rujuk Kasus',
        value: 'RK'
      },
      {
        text: 'Rujuk Parsial',
        value: 'RP'
      },
      {
        text: 'Rujuk Balik',
        value: 'RB'
      }
    ],
    jenisRujukan: [{
        "text": "Puskesmas/Klinik/Dokter Keluarga",
        "value": 1
      },
      {
        "text": "Rumah Sakit",
        "value": 2
      }
    ],
    jenisDokumen: [{
        "text": 'Surat Rujukan',
        "value": "R"
      },
      {
        "text": 'Surat Kontrol',
        "value": "K"
      }
    ],

    statusJaminan: [{
        text: 'Semua Cara Bayar',
        value: 'ALL'
      },
      {
        text: 'Umum/Pribadi',
        value: 1
      },
      {
        text: 'Asuransi/Perusahaan',
        value: 2
      },
      {
        text: 'BPJS Kesehatan',
        value: 3
      },
    ],

    caraMasuk: [{
      text: 'Semua',
      value: 'ALL'
    }, {
      text: 'POLI',
      value: 'RAJAL'
    }, {
      text: 'UGD',
      value: 'UGD'
    }],

    mJaminan: [{
        text: 'Umum/Pribadi',
        value: 1
      },
      {
        text: 'Asuransi/Perusahaan',
        value: 2
      },
      {
        text: 'BPJS Kesehatan',
        value: 3
      },
    ],
    statusPemeriksaanOpt: [{
        text: 'Poliklinik',
        value: 'Poli'
      },
      {
        text: 'Penunjang',
        value: 'Penunjang'
      },
    ],

    statusPemeriksaanOpt2: [{
      text: 'Poliklinik',
      value: 'Poli'
    }, ],

    diagnosaRujukBalik: [{
        value: "01",
        text: "Diabetes Mellitus"
      },
      {
        value: "02",
        text: "Hypertensi"
      },
      {
        value: "03",
        text: "Asthma"
      },
      {
        value: "04",
        text: "Penyakit Jantung"
      },
      {
        value: "05",
        text: "PPOK (Penyakit Paru Obstruktif Kronik)"
      },
      {
        value: "06",
        text: "Schizophrenia"
      },
      {
        value: "07",
        text: "Stroke"
      },
      {
        value: "08",
        text: "Epilepsi"
      },
      {
        value: "09",
        text: "Systemic Lupus Erythematosus"
      }
    ],
    mWaktuPeriksa: [{
      "text": "Pagi (06:30 s/d 14:00)",
      "value": "06:30 s/d 14:00",
      "started": "06:30",
      "ended": "14:00"
    }, {
      "text": "Sore (14:00 s/d 17:00)",
      "value": "14:00 s/d 17:00",
      "started": "14:00",
      "ended": "17:00"
    }, {
      "text": "Malam (17:00 s/d 22:00)",
      "value": "17:00 s/d 22:00",
      "started": "17:00",
      "ended": "22:00"
    }],

    statusPenunjangOpt: [{
        text: 'Laboratorium',
        value: 'LAB'
      },
      {
        text: 'Radiologi',
        value: 'RADIO'
      },
      {
        text: 'Fisioterapi',
        value: 'FISIO'
      },
      {
        text: 'Konsul Gizi',
        value: 'DIETISIEN'
      },
    ],
    mFastTrack: [{
        text: 'Menggunakan Alat Bantu',
        value: 'Y'
      },
      {
        text: 'Lainnya',
        value: 'N'
      },
    ],
    mTypeJaminan: [{
        text: "BPJS Kesehatan",
        value: "B"
      },
      {
        text: "Umum",
        value: "U"
      },
    ],
    mPelayanan: [{
        text: "Rawat Inap",
        value: "RI"
      },
      {
        text: "Rawat Jalan",
        value: "RJ"
      },
    ],
    mRujukan: [{
        text: "Penuh",
        value: "F"
      },
      {
        text: "Partial",
        value: "P"
      },
      {
        text: "Rujuk Balik(Non PRBI)",
        value: "B"
      },
    ],


    mRujukanUGD: [{
        text: "Penuh",
        value: "0"
      },
      {
        text: "Partial",
        value: "1"
      }
    ],

    mTypeSEP: [{
        text: "Terbit SEP Normal",
        value: "N"
      },
      {
        text: "Terbit SEP Kontrol",
        value: "K"
      },
      // {
      //   text: "Terbit SEP Rawat Inap",
      //   value: "R"
      //   // lumayan buat ranap
      // },
      // {
      //   text: "Terbit SEP UGD",
      //   value: "I"
      // },
      {
        text: "Terbit SEP POST OPNAME",
        value: "O"
      },

    ],


    mTypeSEPRanap: [{
      text: "Terbit SEP Rawat Inap",
      value: "R"
    }],

    mTypeSEPUGD: [{
      text: "Terbit SEP UGD",
      value: "I"
    }],

    mVerifFarmasi: [{
        text: "Dalam Proses",
        value: "N"
      },
      {
        text: "Verifikasi BPJS",
        value: "B"
      },
      {
        text: "Verifikasi Asuransi",
        value: "A"
      }
    ],

    mUnitCategory: [{
        text: "Admisi",
        value: "admisi"
      },
      {
        text: 'Admisi Ranap',
        value: 'ranap'
      },
      {
        text: 'Informasi & Booking',
        value: 'informasi'
      },
      {
        text: "Loket Perawat",
        value: "loket"
      },
      {
        text: "Penunjang",
        value: "penunjang"
      },
      {
        text: "Poli",
        value: "poli"
      },
      {
        text: "Farmasi",
        value: "farmasi"
      },
      {
        text: "Admisi UGD",
        value: "admisi-ugd"
      },
      {
        text: "Farmasi UGD",
        value: "farmasi-ugd"
      },
    ],
    typeRujukan: [{
      text: "Rujuk Internal",
      value: "RI"
    }, {
      text: "Rujuk Kasus",
      value: "RK"
    }, {
      text: "Rujuk Parsial",
      value: "RP"
    }, {
      text: "Rujuk Balik (PRB)",
      value: "RB"
    }, {
      text: "Rujuk Balik (NON PRB)",
      value: "RBN"
    }, ],
    typeRujukanV2: [{
      text: "Rujuk Internal",
      value: "RI"
    }, {
      text: "Rujuk Kasus",
      value: "RK"
    }, {
      text: "Rujuk Parsial",
      value: "RP"
    }, ],
    alasanRujuk: [{
      text: "Klinis",
      value: "Klinis"
    }, {
      text: "Non Klinis",
      value: "Non Klinis"
    }, {
      text: "Lain-lain",
      value: "Lain-lain"
    }, ],
    mediaTerpasang: [{
      text: "Infus",
      value: "Infus"
    }, {
      text: "NGT",
      value: "NGT"
    }, {
      text: "DC",
      value: "DC"
    }, {
      text: "Oksigen",
      value: "Oksigen"
    }, ],

    mediaTerpasangUGD: [{
      text: "Infus",
      value: "Infus"
    }, {
      text: "NGT",
      value: "NGT"
    }, {
      text: "Kateter Urine",
      value: "Kateter Urine"
    }, {
      text: "Oksigen",
      value: "Oksigen"
    }, {
      text: "Lain-Lain",
      value: "L"
    }, ],

    jenisFaskes: [{
      text: "Faskes",
      value: 1
    }, {
      text: "Faskes/RS",
      value: 2
    }],

    jenisPelayanan: [{
      text: "Rawat Inap",
      value: 1
    }, {
      text: "Rawat Jalan",
      value: 2
    }],

    statusKlaim: [{
      text: "Proses Verifikasi",
      value: 1
    }, {
      text: "Pending Verifikasi",
      value: 2
    }, {
      text: "Klaim",
      value: 3
    }],

    typeRujukanRS: [{
      text: "PCare",
      value: 1
    }, {
      text: "Rumah Sakit",
      value: 2
    }],

    jenisNOKA: [{
      text: "NIK",
      value: "nik"
    }, {
      text: "Nomor Kartu",
      value: "noka"
    }],

    jenisFormat: [{
      text: "Tanggal Entri",
      value: 1
    }, {
      text: "Tanggal R. Kontrol",
      value: 2
    }],

    jenisKontrol: [{
      text: "SPRI (Nomor Kartu)",
      value: 1
    }, {
      text: "Rencana Kontrol (Nomor SEP)",
      value: 2
    }],

    configLakaLantas: [{
        value: "0",
        text: "Bukan Kecelakaan lalu lintas [BKLL]"
      },
      {
        value: 1,
        text: "KLL dan bukan kecelakaan Kerja [BKK]"
      },
      {
        value: 2,
        text: "KLL dan KK"
      },
      {
        value: 3,
        text: "KK"
      },
    ],

    configTujuan: [{
        value: "0",
        text: "Normal"
      },
      {
        value: "1",
        text: "Prosedur"
      },
      {
        value: "2",
        text: "Konsul Dokter"
      }
    ],

    configLayanan: [{
        value: "1",
        text: "Poli spesialis tidak tersedia pada hari sebelumnya"
      },

      {
        value: "2",
        text: "Jam Poli telah berakhir pada hari sebelumnya"
      },

      {
        value: "3",
        text: "Dokter Spesialis yang dimaksud tidak praktek pada hari sebelumnya"
      },

      {
        value: "4",
        text: "Atas Instruksi RS"
      },

      {
        value: "5",
        text: "Tujuan Kontrol"
      },
    ],

    configPenunjang: [{
        value: 1,
        text: "Radioterapi"
      },

      {
        value: 2,
        text: "Kemoterapi"
      },

      {
        value: 3,
        text: "Rehabilitasi Medik"
      },

      {
        value: 4,
        text: "Rehabilitasi Psikososial"
      },

      {
        value: 5,
        text: "Transfusi Darah"
      },

      {
        value: 6,
        text: "Pelayanan Gigi"
      },

      {
        value: 7,
        text: "Laboratorium"
      },

      {
        value: 8,
        text: "USG"
      },

      {
        value: 9,
        text: "Farmasi"
      },

      {
        value: 10,
        text: "Lain - Lain"
      },

      {
        value: 11,
        text: "MRI"
      },

      {
        value: 12,
        text: "HEMODIALISA"
      },
    ],
    configFlag: [{
        value: 0,
        text: "Prosedur Tidak Berkelanjutan"
      },

      {
        value: 1,
        text: "Prosedur dan Terapi Berkelanjutan"
      },
    ],
    kelasRawat: [{
        value: 1,
        text: "Kelas 1"
      },
      {
        value: 2,
        text: "Kelas 2"
      },
      {
        value: 3,
        text: "Kelas 3"
      },
    ],


    configStatusKondisi: [{
        text: 'Baik',
        value: 'Baik'
      },
      {
        text: 'Lemah',
        value: 'Lemah'
      },
      {
        text: 'Pucat',
        value: 'Pucat'
      },
      {
        text: 'Sesak',
        value: 'Sesak'
      },
      {
        text: 'Sianosis',
        value: 'Sianosis'
      },
    ],

    configStatusGSCE: [{
        text: '(1): tidak ada respon meskipun sudah dirangsan',
        value: 1
      },
      {
        text: '(2): dengan memberikan rangsangan nyeri, misalnya menekan kuku jari',
        value: 2
      },
      {
        text: '(3): dengan rangsang suara, dilakukan dengan menyuruh pasien untuk',
        value: 3
      },
      {
        text: '(4): spontan membuka mata dengan sendirinya tanpa dirangsang',
        value: 4
      },
    ],

    configStatusGSCV: [{
        text: '(1): tidak ada respon',
        value: 1
      },
      {
        text: '(2): suara tanpa arti (mengerang)',
        value: 2
      },
      {
        text: '(3): mengucapkan kata-kata yang tidak jelas',
        value: 3
      },
      {
        text: '(4): bingung, berbicara mengacau (berulang-ulang), disorientasi tempat dan waktu',
        value: 4
      },
      {
        text: '(5): orientasi baik, bicaranya jelas',
        value: 5
      },
    ],

    configStatusGSCM: [{
        text: '(1): tidak ada respon',
        value: 1
      },
      {
        text: '(2): ekstensi abnormal',
        value: 2
      },
      {
        text: '(3): fleksi abnormal',
        value: 3
      },
      {
        text: '(4): menarik anggotak gerak karena rangsangan nyeri',
        value: 4
      },
      {
        text: '(5): menarik anggota gerak karena sentuhan',
        value: 5
      },
      {
        text: '(6): bergerak spontan',
        value: 6
      },
    ],

    configStatusKonjungtiva: [{
        text: "Anemis",
        value: "Anemis",
      },
      {
        text: "Tidak Anemis",
        value: "Tidak Anemis",
      },
    ],

    configStatusSklera: [{
        text: "Ikterik",
        value: "Ikterik",
      },
      {
        text: "Tidak Ada Masalah",
        value: "Tidak Ada Masalah",
      },
    ],

    configStatusHidung: [{
        text: "Epitaktis",
        value: "Epitaktis",
      },
      {
        text: "Epitaktis",
        value: "Asimetris",
      },
      {
        text: "Tidak Ada Masalah",
        value: "Tidak Ada Masalah",
      },
    ],

    configStatusParu: [{
        text: "Simetris",
        value: "Simetris",
      },
      {
        text: "Tidak Simetris",
        value: "Tidak Simetris",
      },
      {
        text: "Tidak Ada Masalah",
        value: "Tidak Ada Masalah",
      },
      {
        text: "Lain-lain",
        value: "Lain-lain",
      },
    ],


    configStatusRespirasi: [{
        text: "Tidak ada Kesulitan",
        value: "Tidak ada Kesulitan",
      },
      {
        text: "Dyspnea",
        value: "Dyspnea",
      },
      {
        text: "Wheezing",
        value: "Wheezing",
      },
      {
        text: "Hemaptoe",
        value: "Hemaptoe",
      },
      {
        text: "Ronchi di paru kanan / kiri",
        value: "Ronchi di paru kanan / kiri",
      }
    ],

    configStatusJantung: [{
        text: "Nyeri Dada",
        value: "Nyeri Dada",
      },
      {
        text: "Aritmia",
        value: "Aritmia",
      },
      {
        text: "Trakikardi",
        value: "Trakikardi",
      },
      {
        text: "Bradikardi",
        value: "Bradikardi",
      },
      {
        text: "Tidak ada masalah",
        value: "Tidak ada masalah",
      },
      {
        text: "Lain-lain",
        value: "Lain-lain",
      }
    ],

    configKondisiAbdomen: [{
        text: "Distesi",
        value: "Distesi",
      },
      {
        text: "Asites",
        value: "Asites",
      },
      {
        text: "Lain-lain",
        value: "Lain-lain",
      }
    ],

    configAkaral: [{
        text: "Dingin",
        value: "Dingin",
      },
      {
        text: "Hangat",
        value: "Hangat",
      }
    ],

    statusRujukanUGD: [{
        text: 'Semua Rujukan',
        value: 'ALL'
      },
      {
        text: 'Datang Sendiri',
        value: 1
      },
      {
        text: 'Rujukan Dokter',
        value: 2
      },
      {
        text: 'Rujukan Rumah Sakit',
        value: 3
      },
      {
        text: 'Rujukan Puskesmas',
        value: 4
      },
    ],

    statusRanap: [
      {
        text: "Semua Status",
        value: "ALL",
      },
      {
        text: "Dalam Perawatan",
        value: 2,
      },
      {
        text: "Keluar Rawat Inap",
        value: 3,
      },
      {
        text: "Verifikasi RM",
        value: 4,
      },
      {
        text: "Verifikasi UPLA",
        value: 5,
      },
      {
        text: "Verifikasi Dokter Verifikator",
        value: 7,
      }, {
        text: "Verifikasi Piutang",
        value: 8,
      },
      {
        text: "Selesai",
        value: 6,
      },
      {
        text: "Void",
        value: 99,
      },
    ],

    statusRanapPasienSelesai: [{
        text: "Semua Status",
        value: "ALL",
      },
      {
        text: "Keluar Rawat Inap",
        value: 3,
      },
      {
        text: "Verifikasi RM",
        value: 4,
      },
      {
        text: "Verifikasi UPLA",
        value: 5,
      },
      {
        text: "Verifikasi Dokter Verifikator",
        value: 7,
      }, 
      {
        text: "Verifikasi Piutang",
        value: 8,
      },
      {
        text: "Selesai",
        value: 6,
      }
    ],

    statusRanapVerifRM: [{
        text: "Semua Status",
        value: "ALL",
      },
      {
        text: "Verifikasi RM",
        value: 4,
      },
      {
        text: "Verifikasi UPLA",
        value: 5,
      },
      {
        text: "Verifikasi Dokter Verifikator",
        value: 7,
      }, {
        text: "Verifikasi Piutang",
        value: 8,
      },
      {
        text: "Selesai",
        value: 6,
      }
    ],
    statusRanapVerifUpla: [{
        text: "Semua Status",
        value: "ALL",
      },
      {
        text: "Verifikasi UPLA",
        value: 5,
      },
      {
        text: "Verifikasi Dokter Verifikator",
        value: 7,
      }, {
        text: "Verifikasi Piutang",
        value: 8,
      },
      {
        text: "Selesai",
        value: 6,
      }
    ],
    statusRanapVerifDokter: [{
        text: "Semua Status",
        value: "ALL",
      },
      {
        text: "Verifikasi Dokter Verifikator",
        value: 7,
      }, {
        text: "Verifikasi Piutang",
        value: 8,
      }, {
        text: "Selesai",
        value: 6,
      }
    ],
    statusRanapVerifPiutang: [{
        text: "Semua Status",
        value: "ALL",
      },{
        text: "Verifikasi Piutang",
        value: 8,
      }, {
        text: "Selesai",
        value: 6,
      }
    ],

    statusUGDRujukan: [{
        text: 'Datang Sendiri',
        value: 1
      },
      {
        text: 'Rujukan Dokter',
        value: 2
      },
      {
        text: 'Rujukan Rumah Sakit',
        value: 3
      },
      {
        text: 'Rujukan Puskesmas',
        value: 4
      },
    ],

    statusPersetujuanPasien: [{
        text: "Pasien",
        value: "P"
      },
      {
        text: "Penanggungjawab / Wali Hukum",
        value: "W"
      }
    ],

    izinYesNo: [{
        text: "Mengizinkan",
        value: "Y"
      },
      {
        text: "Tidak Mengizinkan",
        value: "N"
      }
    ],

    monthName: [{
        text: "Januari",
        value: "01"
      },
      {
        text: "Februari",
        value: "02"
      },
      {
        text: "Maret",
        value: "03"
      },
      {
        text: "April",
        value: "04"
      },
      {
        text: "Mei",
        value: "05"
      },
      {
        text: "Juni",
        value: "06"
      },
      {
        text: "July",
        value: "07"
      },
      {
        text: "Agustus",
        value: "08"
      },
      {
        text: "September",
        value: "09"
      },
      {
        text: "Oktober",
        value: "10"
      },
      {
        text: "November",
        value: "11"
      },
      {
        text: "Desember",
        value: "12"
      }
    ],

    rsTime: [{
        text: "Waktu Sesuai Server",
        value: "server"
      },
      {
        text: "Waktu Sesuai RS",
        value: "rs"
      }
    ],

    caraDaftar: [{
        text: "Pendaftaran Offline",
        value: "BO-REG"
      },
      {
        text: "Pendaftaran Online",
        value: "E-REG"
      },
      {
        text: "ICS",
        value: "ICS"
      },
      {
        text: "Moblie JKN",
        value: "JKN"
      },
    ],

    mCaraBayarText: [
      {
        text: "BPJS",
        value: "bpjs"
      },
      {
        text: "Asuransi",
        value: "asuransi"
      },
      {
        text: "Umum",
        value: "umum"
      },
    ],

    typeFilterBelumLayan: [{
        text: "Antrean Belum Dilayani",
        value: "AB"
      },
      {
        text: "Antrean Pertanggal",
        value: "AP"
      },
      {
        text: "Antrean Perkode Booking",
        value: "AK"
      }
    ],

    typeDashboard: [{
        text: "Dashboard Pertanggal",
        value: "DT"
      },
      {
        text: "Dashboard Perbulan",
        value: "DB"
      }
    ],

    typeHariLibur: [{
        text: "Hari Libur Tiap Tahun",
        value: "Tetap"
      },
      {
        text: "Hari libur hanya tahun ini",
        value: "Pertahun"
      }
    ],

    // UGD START
    // Risiko Luka Decubitus

    rldKondisiFisik: [{
        text: "Baik",
        value: 4
      },
      {
        text: "Sedang",
        value: 3
      },
      {
        text: "Buruk",
        value: 2
      },
      {
        text: "Sangat Buruk",
        value: 1
      },
    ],
    rldKesadaran: [{
        text: "Komposmentis",
        value: 4
      },
      {
        text: "Apatis",
        value: 3
      },
      {
        text: "Konfus / Soporus",
        value: 2
      },
      {
        text: "Stupor / Koma",
        value: 1
      },
    ],
    rldAktivitas: [{
      text: "Ambulan",
      value: 4
    }, {
      text: "Ambulan dengan Bantuan",
      value: 3
    }, {
      text: "Hanya Bisa Duduk",
      value: 2
    }, {
      text: "Tiduran",
      value: 1
    }, ],
    rldMobilitas: [{
      text: "Bergerak Bebas",
      value: 4
    }, {
      text: "Sedikit Terbatas",
      value: 3
    }, {
      text: "Sangat Terbatas",
      value: 2
    }, {
      text: "Tidak Bisa Bergerak",
      value: 1
    }],
    rldInkontinensia: [{
      text: "Tidak",
      value: 4
    }, {
      text: "Kadang-Kadang",
      value: 3
    }, {
      text: "Sering Inkontinensia Urin",
      value: 2
    }, {
      text: "Inkontinensia Urin dan Alvi",
      value: 1
    }],


    // UGD EDOMSON Perawat
    edUmur: [{
      text: "< 50 tahun",
      value: 8
    }, {
      text: "50 - 79 tahun",
      value: 10
    }, {
      text: "> 80 tahun",
      value: 25
    }],

    edStatusMental: [{
      text: "Kesadaran / Orientasi Baik",
      value: 4
    }, {
      text: "Ada Kecemasan / Agitasi",
      value: 12
    }, {
      text: "Kadang-kadang Bingung",
      value: 13
    }, {
      text: "Confusion / Disorientasi",
      value: 14
    }],

    edPolaBAB: [{
      text: "Mampu Mengontrol BAB/BAK dengan Baik",
      value: 8
    }, {
      text: "Memakai DC",
      value: 11
    }, {
      text: "BAK / BAB Dibantu",
      value: 10
    }, {
      text: "Inkontinensia, Nocturia, Frekuensi",
      value: 12
    }],

    edPengobatan: [{
      text: "Tidak Memakai Obat-obatan",
      value: 10
    }, {
      text: "Memakai Obat-obatan Jantung",
      value: 8
    }, {
      text: "Memakai Obat Psikotropika dan Anti Depresan",
      value: 10
    }, {
      text: "Memakai Obat Anestesi, Nyeri dalam 24 Jam Terakhir",
      value: 12
    }],

    edDiagnosis: [{
      text: "Gangguan Bipolar / Schizophrenia",
      value: 10
    }, {
      text: "Penyalahgunaan Alkohol / Zat Lain",
      value: 8
    }, {
      text: "Gangguan Depresi Mayor",
      value: 10
    }, {
      text: "Gangguan Demensia / Dilirium",
      value: 12
    }],

    edAmbulasi: [{
      text: "Mandiri",
      value: 7
    }, {
      text: "Mobilisasi Pakai Alat Bantu",
      value: 8
    }, {
      text: "Vertigo / Hipotensi Orthostatic / Kelemahan Anggota Gerak",
      value: 10
    }, {
      text: "Tidak Siap / Lupa Keterbatasan Diri",
      value: 15
    }],

    edNutrisi: [{
      text: "Intake Makan / Minum Kurang dalam 24 Jam",
      value: 12
    }, {
      text: "Makan Minum Cukup",
      value: 0
    }],

    edRiwayatjatuh: [{
      text: "Tidak Ada Riwayat Jatuh",
      value: 8
    }, {
      text: "Ada Riwayat Jatuh Dalam 3 Bulan Terakhir",
      value: 14
    }],

    // humpty dumpy
    "hdUmur": [{
        "text": "Lebih dari 13 Tahun",
        "value": 1
      },
      {
        "text": "7 - 13 Tahun",
        "value": 2
      },
      {
        "text": "3 - 7 Tahun",
        "value": 3
      },
      {
        "text": "Kurang dari 3 Tahun",
        "value": 4
      }
    ],
    "hdJK": [{
        "text": "Perempuan",
        "value": 1
      },
      {
        "text": "Laki-Laki",
        "value": 2
      }
    ],
    "hdDiagnosis": [{
        "text": "Diagnosa Lain",
        "value": 1
      },
      {
        "text": "Gangguan Perilaku",
        "value": 2
      },
      {
        "text": "Perubahan dalam Oksigenasi, Anoreksia, Pusing",
        "value": 3
      },
      {
        "text": "Penyakit Neurologi",
        "value": 4
      }
    ],
    "hdGangguanKognitif": [{
        "text": "Orientasi Baik Terhadap Diri Sendiri",
        "value": 1
      },
      {
        "text": "Lupa Akan Adanya Keterbatasan",
        "value": 2
      },
      {
        "text": "Tidak Menyadari Keterbatasan Dirinya",
        "value": 3
      }
    ],
    "hdFaktorLingkungan": [{
        "text": "Pasien di Area Rawat Jalan",
        "value": 1
      },
      {
        "text": "Pasien Diletakkan di Bed",
        "value": 2
      },
      {
        "text": "Pasien Menggunakan Alat Bantu Bayi",
        "value": 3
      },
      {
        "text": "Riwayat Jatuh Saat Toddler (1-3 Tahun)",
        "value": 4
      }
    ],
    "hdPembedahan": [{
        "text": "Lebih dari 48 Jam",
        "value": 1
      },
      {
        "text": "Dalam Waktu 48 Jam",
        "value": 2
      },
      {
        "text": "Dalam Waktu 24 Jam",
        "value": 3
      },
      {
        "text": "Tidak ada pembedahan",
        "value": 0
      },
    ],
    "hdPenggunaanObat": [{
        "text": "Obat Lain",
        "value": 1
      },
      {
        "text": "Penggunaan salah satu dari obat tertulis",
        "value": 2
      },
      {
        "text": "Sedative, Obat Hypnosis, Barbbiturate, Fenotazine, Anti Depresan, Pencahar, Deuretic, Narcose",
        "value": 3
      }
    ],

    // skala nyeri ugd
    snRiwayatJatuh: [{
      text: "Tidak",
      value: 0
    }, {
      text: "Ya",
      value: 25
    }],

    snDiagnosa: [{
      text: "Tidak",
      value: 0
    }, {
      text: "Ya",
      value: 25
    }],

    snAlatBantuJalan: [{
      text: "Bedrest Dibantu Perawat/Bidan/Tidak ada",
      value: 0
    }, {
      text: "Penopang/Tongkat/Walker",
      value: 15
    }, {
      text: "Furniture",
      value: 30
    }],

    snPakaiInfus: [{
      text: "Tidak",
      value: 0
    }, {
      text: "Ya",
      value: 25
    }],

    snCaraJalan: [{
      text: "Normal/Bedrest/Immobilisasi",
      value: 0
    }, {
      text: "Lemah",
      value: 15
    }, {
      text: "Terganggu",
      value: 30
    }],

    snStatusMental: [{
      text: "Orientasi Sesuai Kemampuan",
      value: 0
    }, {
      text: "Lupa Keterbatasan Diri",
      value: 15
    }],

    // pengkajian tingkat kesadaran
    ksResponseMata: [{
      text: "Dapat Membuka Mata Secara Spontan",
      value: 4
    }, {
      text: "Dapat Membuka Mata Sebagai Respons Terhadap Perintah Verbal",
      value: 3
    }, {
      text: "Membuka Mata Sebagai Respons Terhadap Rangsangan Nyeri atau Paksaan",
      value: 2
    }, {
      text: "Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun",
      value: 1
    }],

    ksResponseVerbal: [{
      text: "Pasien Sadar dan Merespons Pertanyaan dengan Benar",
      value: 5
    }, {
      text: "Pasien Bingung atau Disorientasi",
      value: 4
    }, {
      text: "Pasien Memberikan Respons Tidak Sesuai dengan Instruksi atau Pertanyaan",
      value: 3
    }, {
      text: "Pasien Hanya Dapat Mengeluarkan Suara yang Tidak Dapat Dipahami",
      value: 2
    }, {
      text: "Pasien Tidak Memberikan Respons Verbal Terhadap Rangsangan Apapun",
      value: 1
    }],

    ksResponseMotorik: [{
      text: "Pasien Dapat Melakukan Gerakan Sesuai Perintah",
      value: 6
    }, {
      text: "Pasien Dapat Mengarahkan Gerakan Ke Sumber Rangsangan",
      value: 5
    }, {
      text: "Terjadi Fleksi atau Pasien Menarik atau Menghindari Rangsangan Nyeri",
      value: 4
    }, {
      text: "Pasien Menunjukkan Gerakan Fleksi Sebagai Respons Terhadap Rangsangan",
      value: 3
    }, {
      text: "Pasien Menunjukkan Gerakan Ekstensi Sebagi Respons Terhadap Rangsangan",
      value: 2
    }, {
      text: "Pasien Tidak Memberikan Respons Motorik Terhadap Rangsangan Apa Pun",
      value: 1
    }],


    ksResponseMataAnak: [{
      text: "Dapat Membuka Mata Secara Spontan",
      value: 4
    }, {
      text: "Dapat Membuka Mata Sebagai Respons Terhadap Perintah Verbal",
      value: 3
    }, {
      text: "Membuka Mata Sebagai Respons Terhadap Rangsangan Nyeri atau Paksaan",
      value: 2
    }, {
      text: "Tidak Dapat Membuka Mata Sama Sekali Terhadap Rangsangan Apapun",
      value: 1
    }],

    ksResponseVerbalAnak: [{
      text: "Berceloteh",
      value: 5
    }, {
      text: "Menangis,gelisah",
      value: 4
    }, {
      text: "Menangis Terhadap rangsang nyeri",
      value: 3
    }, {
      text: "Merintih,Mengerang",
      value: 2
    }, {
      text: "Pasien tidak memberikan respons verbal terhadap rangsangan apapun",
      value: 1
    }],

    ksResponseMotorikAnak: [{
      text: "Pasien Dapat Melakukan Gerakan Sesuai Perintah",
      value: 6
    }, {
      text: "Pasien Menarik diri terhadap sentuhan",
      value: 5
    }, {
      text: "Pasien menarik atau menghindari rangsangan nyeri",
      value: 4
    }, {
      text: "Pasien Menunjukkan Gerakan Fleksi Sebagai Respons Terhadap Rangsangan",
      value: 3
    }, {
      text: "Pasien Menunjukkan Gerakan Ekstensi Sebagi Respons Terhadap Rangsangan",
      value: 2
    }, {
      text: "Pasien Tidak Memberikan Respons Motorik Terhadap Rangsangan Apa Pun",
      value: 1
    }],

    "ksResponseMataNICU": [
      {
        "text": "Spontan",
        "value": 4
      },
      {
        "text": "Membuka mata saat diperintah atau mendengar suara",
        "value": 3
      },
      {
        "text": "Membuka mata saat ada rangsangan nyeri",
        "value": 2
      },
      {
        "text": "Tidak ada respon",
        "value": 1
      }
    ],
    "ksResponseVerbalNICU": [
      {
        "text": "Berbicara mengoceh seperti biasa",
        "value": 5
      },
      {
        "text": "Menangis lemah",
        "value": 4
      },
      {
        "text": "Menangis karena diberi rangsangan nyeri",
        "value": 3
      },
      {
        "text": "Merintih karena diberi rangsangan nyeri",
        "value": 2
      },
      {
        "text": "Tidak ada respon",
        "value": 1
      }
    ],
    "ksResponseMotorikNICU": [
      {
        "text": "Bergerak spontan",
        "value": 6
      },
      {
        "text": "Menarik anggota gerak karena sentuhan",
        "value": 5
      },
      {
        "text": "Menarik anggota gerak karena rangsangan nyeri",
        "value": 4
      },
      {
        "text": "Fleksi abnormal",
        "value": 3
      },
      {
        "text": "Ekstensi abnormal",
        "value": 2
      },
      {
        "text": "Tidak ada respon",
        "value": 1
      }
    ],

    // sydney

    syTransfer: [{
        text: "Mandiri(boleh menggunakan alat bantu jalan)",
        value: 0
      },
      {
        text: "Memerlukan sedikit bantuan (1 orang) atau dalam pengawasan",
        value: 1
      },
      {
        text: "Memerlukan bantuan yang nyata (2 orang)",
        value: 2
      },
      {
        text: "Tidak dapat duduk seimbang, perlu bantuan total",
        value: 3
      },
    ],

    syMobilitas: [{
        text: "Mandiri (boleh menggunakan alat bantu jalan)",
        value: 0
      },
      {
        text: "Berjalan dengan bantuan 1 orang (verbal/fisik)",
        value: 1
      },
      {
        text: "Menggunakan kursi roda",
        value: 2
      },
      {
        text: "Immobilisasi",
        value: 3
      },
    ],

    // penurunan berat ugd

    cfPenurunanBeratUGD: [{
        text: "Tidak ada penurunan berat badan",
        value: "T",
        number: 0,
      },
      {
        text: "Tidak yakin/Tidak tahu/Baju terasa longgar",
        value: "TY",
        number: 2,
      },
    ],
    cfPenurunanBeratUGDLain: [{
      text: "1-5kg",
      value: 1,
    }, {
      text: "6-10kg",
      value: 2,
    }, {
      text: "11-15kg",
      value: 3,
    }, {
      text: ">15kg",
      value: 4,
    }, ],
    configVitalSignUGD: [{
        'value': 'auod_vs_kepala',
        'label': 'Kepala',
        'isOpen': false
      },
      {
        'value': 'auod_vs_mata',
        'label': 'Mata',
        'isOpen': false
      },
      {
        'value': 'auod_vs_hidung',
        'label': 'Hidung',
        'isOpen': false
      },
      {
        'value': 'auod_vs_rambut',
        'label': 'Rambut',
        'isOpen': false
      },
      {
        'value': 'auod_vs_bibir',
        'label': 'Bibir',
        'isOpen': false
      },
      {
        'value': 'auod_vs_gigi',
        'label': 'Gigi Geligi',
        'isOpen': false
      },
      {
        'value': 'auod_vs_lidah',
        'label': 'Lidah',
        'isOpen': false
      },
      {
        'value': 'auod_vs_langit',
        'label': 'Langit - Langit',
        'isOpen': false
      },
      {
        'value': 'auod_vs_leher',
        'label': 'Leher',
        'isOpen': false
      },
      {
        'value': 'auod_vs_tenggorokan',
        'label': 'Tenggorokan',
        'isOpen': false
      },
      {
        'value': 'auod_vs_tonsil',
        'label': 'Tonsil',
        'isOpen': false
      },
      {
        'value': 'auod_vs_dada',
        'label': 'Dada',
        'isOpen': false
      },
      {
        'value': 'auod_vs_payudara',
        'label': 'Payudara',
        'isOpen': false
      },
      {
        'value': 'auod_vs_punggung',
        'label': 'Punggung',
        'isOpen': false
      },
      {
        'value': 'auod_vs_perut',
        'label': 'Perut',
        'isOpen': false
      },
      {
        'value': 'auod_vs_genital',
        'label': 'Genital',
        'isOpen': false
      },
      {
        'value': 'auod_vs_anus',
        'label': 'Anus/Dubur',
        'isOpen': false
      },
      {
        'value': 'auod_vs_lengan_atas',
        'label': 'Lengan Atas',
        'isOpen': false
      },
      {
        'value': 'auod_vs_lengan_bawah',
        'label': 'Lengan Bawah',
        'isOpen': false
      },
      {
        'value': 'auod_vs_jari_tangan',
        'label': 'Jari Tangan',
        'isOpen': false
      },
      {
        'value': 'auod_vs_kuku',
        'label': 'Kuku Tangan',
        'isOpen': false
      },
      {
        'value': 'auod_vs_sendi_tangan',
        'label': 'Pesendian Tangan',
        'isOpen': false
      },
      {
        'value': 'auod_vs_tungkai_atas',
        'label': 'Tungkai Atas',
        'isOpen': false
      },
      {
        'value': 'auod_vs_tungkai_bawah',
        'label': 'Tungkai Bawah',
        'isOpen': false
      },
      {
        'value': 'auod_vs_jari_kaki',
        'label': 'Jari Kaki',
        'isOpen': false
      },
      {
        'value': 'auod_vs_kuku_kaki',
        'label': 'Kuku Kaki',
        'isOpen': false
      },
      {
        'value': 'auod_vs_persendian_kaki',
        'label': 'Persendian Kaki',
        'isOpen': false
      },
      {
        'value': 'auod_vs_saraf',
        'label': 'Saraf',
        'isOpen': false
      }
    ],

    configVitalSignRanapDokter: [{
        'value': 'arankm_vs_kepala',
        'label': 'Kepala',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_mata',
        'label': 'Mata',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_hidung',
        'label': 'Hidung',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_rambut',
        'label': 'Rambut',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_bibir',
        'label': 'Bibir',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_gigi',
        'label': 'Gigi Geligi',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_lidah',
        'label': 'Lidah',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_langit',
        'label': 'Langit - Langit',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_leher',
        'label': 'Leher',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_tenggorokan',
        'label': 'Tenggorokan',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_tonsil',
        'label': 'Tonsil',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_dada',
        'label': 'Dada',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_payudara',
        'label': 'Payudara',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_punggung',
        'label': 'Punggung',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_perut',
        'label': 'Perut',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_genital',
        'label': 'Genital',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_anus',
        'label': 'Anus/Dubur',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_lengan_atas',
        'label': 'Lengan Atas',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_lengan_bawah',
        'label': 'Lengan Bawah',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_jari_tangan',
        'label': 'Jari Tangan',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_kuku',
        'label': 'Kuku Tangan',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_sendi_tangan',
        'label': 'Pesendian Tangan',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_tungkai_atas',
        'label': 'Tungkai Atas',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_tungkai_bawah',
        'label': 'Tungkai Bawah',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_jari_kaki',
        'label': 'Jari Kaki',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_kuku_kaki',
        'label': 'Kuku Kaki',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_persendian_kaki',
        'label': 'Persendian Kaki',
        'isOpen': false
      },
      {
        'value': 'arankm_vs_saraf',
        'label': 'Saraf',
        'isOpen': false
      }
    ],
    configVitalSignRanapVerifikator: [{
        'value': 'aranrv_vs_kepala',
        'label': 'Kepala',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_mata',
        'label': 'Mata',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_hidung',
        'label': 'Hidung',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_rambut',
        'label': 'Rambut',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_bibir',
        'label': 'Bibir',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_gigi',
        'label': 'Gigi Geligi',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_lidah',
        'label': 'Lidah',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_langit',
        'label': 'Langit - Langit',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_leher',
        'label': 'Leher',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_tenggorokan',
        'label': 'Tenggorokan',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_tonsil',
        'label': 'Tonsil',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_dada',
        'label': 'Dada',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_payudara',
        'label': 'Payudara',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_punggung',
        'label': 'Punggung',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_perut',
        'label': 'Perut',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_genital',
        'label': 'Genital',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_anus',
        'label': 'Anus/Dubur',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_lengan_atas',
        'label': 'Lengan Atas',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_lengan_bawah',
        'label': 'Lengan Bawah',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_jari_tangan',
        'label': 'Jari Tangan',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_kuku',
        'label': 'Kuku Tangan',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_sendi_tangan',
        'label': 'Pesendian Tangan',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_tungkai_atas',
        'label': 'Tungkai Atas',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_tungkai_bawah',
        'label': 'Tungkai Bawah',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_jari_kaki',
        'label': 'Jari Kaki',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_kuku_kaki',
        'label': 'Kuku Kaki',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_persendian_kaki',
        'label': 'Persendian Kaki',
        'isOpen': false
      },
      {
        'value': 'aranrv_vs_saraf',
        'label': 'Saraf',
        'isOpen': false
      }
    ],


    // airways
    bebasTidakOpt: [{
        text: 'Bebas',
        value: 'B'
      },
      {
        text: 'Tidak Bebas',
        value: 'TB'
      },
    ],

    positifNegatifOpt: [{
        text: 'Positif',
        value: 'Positif'
      },
      {
        text: 'Negatif',
        value: 'Negatif'
      },
    ],

    suaraNapasOpt: [{
        text: 'Normal',
        value: 'Normal'
      },
      {
        text: 'Stidor',
        value: 'Stidor'
      },
      {
        text: 'Tidak ada suara napas',
        value: 'Tidak ada suara napas'
      },
      {
        text: 'Lain-lain',
        value: 'L'
      },
    ],

    // circulation
    normalTidakNormalOpt: [{
        text: 'Normal',
        value: 'N'
      },
      {
        text: 'Tidak Normal',
        value: 'TN'
      },
    ],

    normalTidakNormalOptTP: [
      {
        text: 'Normal',
        value: 'N'
      }, {
        text: 'Tidak Normal',
        value: 'TN'
      }, {
        text: 'Tidak DiPeriksa',
        value: 'TP'
      },
    ],

    nadikapilerOpt: [{
        text: '< 2',
        value: '< 2'
      },
      {
        text: '> 2',
        value: '> 2'
      },
    ],

    kuatLemahOpt: [{
        text: 'Kuat',
        value: 'Kuat'
      },
      {
        text: 'Lemah',
        value: 'Lemah'
      },
    ],

    // breathing
    polaNapasOpt: [{
        text: 'Apnea',
        value: 'Apnea'
      },
      {
        text: 'Bradipnea',
        value: 'Bradipnea'
      },
      {
        text: 'Sesak',
        value: 'Sesak'
      },
      {
        text: 'Takipnea',
        value: 'Takipnea'
      },
      {
        text: 'Orthopnea',
        value: 'Orthopnea'
      },
    ],

    bunyiNapasOpt: [{
        text: 'Vesikuler',
        value: 'Vesikuler'
      },
      {
        text: 'Wheezing',
        value: 'Wheezing'
      },
      {
        text: 'Stidor',
        value: 'Stidor'
      },
      {
        text: 'Ronchi',
        value: 'Ronchi'
      }
    ],

    tandaDistressOpt: [{
        text: 'Penggunaan otot bantu',
        value: 'Penggunaan otot bantu'
      },
      {
        text: 'Retraksi dada/interkosta',
        value: 'Retraksi dada/interkosta'
      },
      {
        text: 'Cuping hidung',
        value: 'Cuping hidung'
      }
    ],

    jenisNapasOpt: [{
        text: 'Pernafasan Dada',
        value: 'Pernafasan Dada'
      },
      {
        text: 'Pernafasan Perut',
        value: 'Pernafasan Perut'
      },
      {
        text: 'Lain-lain',
        value: 'L'
      }
    ],

    disabilty: [{
      text: 'Pupil Anisokor',
      value: 'Pupil Anisokor'
    }, {
      text: 'Kejang',
      value: 'Kejang'
    }],

    pasienSetuju: [{
      text: 'Tindakan disetujui',
      value: 'Y'
    }, {
      text: 'Tindakan ditolak',
      value: 'N'
    }],

    persetujuanTindakan: [{
      text: 'Pasien',
      value: 'P'
    }, {
      text: 'Penanggungjawab Pasien',
      value: 'PJ'
    }],

    tindakLanjutUGD: [{
      text: 'Rujuk',
      value: 'RU'
    }, {
      text: 'Rawat Jalan',
      value: 'RJ'
    }, {
      text: 'Rawat Inap',
      value: 'RI'
    }, ],

    tindakLanjutRawatJalan: [{
      text: 'Keluhan Membaik',
      value: 'KM'
    }, {
      text: 'Meninggal di UGD',
      value: 'MU'
    }, {
      text: 'DOA/Meninggal',
      value: 'DOA'
    }, {
      text: 'Menolak Rawat Inap',
      value: 'MR'
    }],

    pasienMasukKeRuangan: [{
      text: "Ruang Rawat Inap",
      value: 'PRI'
    }, {
      text: "Ruang ICU",
      value: 'RICU'
    }],

    transportasiPulangUGD: [{
        text: "Kendaraan Pirbadi",
        value: 'Kendaraan Pirbadi'
      },
      {
        text: "Ambulans",
        value: 'Ambulans'
      },
      {
        text: "Ambulans Jenazah",
        value: 'Ambulans Jenazah'
      },
    ],

    pendidikanPasienPulang: [{
        text: "Makan / Minum Obat Rutin",
        value: 'Makan / Minum Obat Rutin'
      },
      {
        text: "Atur Pola Makan",
        value: 'Atur Pola Makan'
      },
      {
        text: "Menjaga Kebersihan Luka",
        value: 'Menjaga Kebersihan Luka'
      },
      {
        text: "Datang Kembali Untuk Kontrol",
        value: 'Datang Kembali Untuk Kontrol'
      },
      {
        text: "Lain-Lain",
        value: 'Lainnya'
      },
    ],


    dnrDibuatAtas: [{
        text: "Atas Permintaan Pasien",
        value: "Pasien",
      },
      {
        text: "Atas Permintaan Keluarga",
        value: "Keluarga",
      },
      {
        text: "Atas Advis DPJP",
        value: "DPJP",
      },
    ],
    ttdCreatedBy: [{
        text: "TTD Basah/Manual",
        value: "Manual"
      },
      {
        text: "TTD Digital",
        value: "Digital"
      }
    ],


    // partus
    pengisianPartus: [{
        text: "Isi di Aplikasi",
        value: "App",
      },
      {
        text: "Isi Manual",
        value: "Manual",
      },
    ],

    partusPersalinan: [{
        text: "Rumah Ibu",
        value: "Rumah Ibu",
      },
      {
        text: "Puskesmas",
        value: "Puskesmas",
      },
      {
        text: "Klinik Swasta",
        value: "Klinik Swasta",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    partusK1: [{
        text: "Partograf melewati garis waspada",
        value: "Partograf melewati garis waspada",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    partusK2Pendamping: [{
        text: "Suami",
        value: "Suami",
      },
      {
        text: "Keluarga",
        value: "Keluarga",
      },
      {
        text: "Teman",
        value: "Teman",
      },
      {
        text: "Dukun",
        value: "Dukun",
      },
      {
        text: "Tidak Ada",
        value: "Tidak Ada",
      },
    ],

    partusK2GawatJanin: [{
        text: "Miringkan ibu ke sisi kiri",
        value: "Miringkan ibu ke sisi kiri",
      },
      {
        text: "Minta Ibu Menarik Napas",
        value: "Minta Ibu Menarik Napas",
      },
      {
        text: "Episiotomi",
        value: "Episiotomi",
      },
    ],

    partusK2Distosia: [{
        text: "Manuver McRobert Ibu Merangkang",
        value: "Manuver McRobert Ibu Merangkang",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    partusK3Tindakan: [{
        text: "Mengeluarkan Secara manual",
        value: "Mengeluarkan Secara manual",
      },
      {
        text: "Merujuk",
        value: "Merujuk",
      },
      {
        text: "Heacting Perineum",
        value: "Heacting Perineum",
      },
      {
        text: "Tindakan lain",
        value: "L",
      },
    ],

    partusK3AtoniaUteri: [{
        text: "Kompresi Bimanual Internal",
        value: "Kompresi Bimanual Internal",
      },
      {
        text: "Metil Ergometrin 0,2 mg IM",
        value: "Metil Ergometrin 0,2 mg IM",
      },
      {
        text: "Oksitosin Drip",
        value: "Oksitosin Drip",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    partusBBLStatus: [{
        text: "Mengeringkan",
        value: "Mengeringkan",
      },
      {
        text: "Menghangatkan",
        value: "Menghangatkan",
      },
      {
        text: "Bebaskan Jalan Napas",
        value: "Bebaskan Jalan Napas",
      },
      {
        text: "Simulasi rangsang aktif",
        value: "Simulasi rangsang aktif",
      },
      {
        text: "Cacat Bawaan",
        value: "Cacat Bawaan",
      },
      {
        text: "Lainnya",
        value: "Lainnya",
      },
    ],

    partusMateri: [{
        text: "Semua Nifas",
        value: "Semua Nifas",
      },
      {
        text: "Breast Care",
        value: "Breast Care",
      },
      {
        text: "ASI",
        value: "ASI",
      },
      {
        text: "Perawatan tali Pusar",
        value: "Perawatan tali Pusar",
      },
      {
        text: "KL",
        value: "KL",
      },
      {
        text: "Gizi",
        value: "Gizi",
      },
      {
        text: "Imunisasi",
        value: "Imunasasi",
      },
    ],
    // partus end

    // BBL

    bblKulit: [{
        text: "Ikteris",
        value: "Ikteris",
      },
      {
        text: "Pucat",
        value: "Pucat",
      },
      {
        text: "Dingin",
        value: "Dingin",
      },
      {
        text: "Odema",
        value: "Odema",
      },
      {
        text: "Sianotik",
        value: "Sianotik",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    bblGerak: [{
        text: "Atas",
        value: "Atas",
      },
      {
        text: "Batas",
        value: "Batas",
      },
      {
        text: "Jari-jari",
        value: "Jari-jari",
      },
      {
        text: "Ujung 2 jari",
        value: "Ujung 2 jari",
      },
      {
        text: "Rajah Jari - 2 Tangan",
        value: "Rajah Jari - 2 Tangan",
      },
      {
        text: "Lainnya",
        value: "L",
      },
    ],

    pernahTidakPernah: [{
        value: "TP",
        text: "Tidak Pernah"
      },
      {
        value: "P",
        text: "Pernah"
      }
    ],

    JenisDarah: [{
        value: "Whole Blood",
        text: "Whole Blood"
      },
      {
        value: "Packed Red Cell",
        text: "Packed Red Cell"
      },
      {
        value: "WE",
        text: "WE"
      },
      {
        value: "FFP",
        text: "FFP"
      },
      {
        value: "PRP",
        text: "PRP"
      },
      {
        value: "Trombosit",
        text: "Trombosit"
      },
      {
        value: "Cryo",
        text: "Cryo"
      },
    ],

    // ugd perawat tindak lanjut

    ugdTindakanLanjut: [{
        value: "L",
        text: "Pilih dari yang ada"
      },
      {
        value: "N",
        text: "Buat baru"
      },
    ],
    ugdAssesmenTindakan: [{
        value: "Memberikan oksigen",
        text: "Memberikan oksigen"
      },
      {
        value: "Melakukan Perekaman EKG",
        text: "Melakukan Perekaman EKG"
      },
      {
        value: "Memasang bedside monitor",
        text: "Memasang bedside monitor"
      },
      {
        value: "Melakukan pemeriksaan cek gula darah acak",
        text: "Melakukan pemeriksaan cek gula darah acak"
      },
      {
        value: "Melakukan pemasangan infus sesuai dengan advis dokter",
        text: "Melakukan pemasangan infus sesuai dengan advis dokter"
      },
      {
        value: "Melaksanakan pemberian injeksi",
        text: "Melaksanakan pemberian injeksi"
      },
      {
        value: "Melakukan pemasangan kateter tetap",
        text: "Melakukan pemasangan kateter tetap"
      },
      {
        value: "Melakukan suction",
        text: "Melakukan suction"
      },
      {
        value: "Melakukan penjahitan luka",
        text: "Melakukan penjahitan luka"
      },
      {
        value: "Melakukan perawatan luka",
        text: "Melakukan perawatan luka"
      },
      {
        value: "Melakukan ekstraksi kuku",
        text: "Melakukan ekstraksi kuku"
      },
      {
        value: "Melakukan incici pada...",
        text: "Melakukan incici pada..."
      },
      {
        value: "Memasang gelang identitas warna...",
        text: "Memasang gelang identitas warna..."
      },
      {
        value: "Memasang gelang penanda alergi",
        text: "Memasang gelang penanda alergi"
      },
      {
        value: "Memasang bidai/spalk",
        text: "Memasang bidai/spalk"
      },
      {
        value: "Melakukan pengambilan benda asing/corpal",
        text: "Melakukan pengambilan benda asing/corpal"
      },
      {
        value: "Melakukan irigasi mata",
        text: "Melakukan irigasi mata"
      },
      {
        value: "Melakukan irigasi telinga",
        text: "Melakukan irigasi telinga"
      },
      {
        value: "Melakukan lavement",
        text: "Melakukan lavement"
      },
      {
        value: "Melakukan pelepasan kateter tetap",
        text: "Melakukan pelepasan kateter tetap"
      },
      {
        value: "Memberikan obat... dengan nebulizer",
        text: "Memberikan obat... dengan nebulizer"
      },
      {
        value: "Memasang NGT",
        text: "Memasang NGT"
      },
      {
        value: "Melakukan perawatan luka combutio",
        text: "Melakukan perawatan luka combutio"
      },
      {
        value: "Memberikan obat melalui titrasi syring pump dengan dosis",
        text: "Memberikan obat melalui titrasi syring pump dengan dosis"
      },
      {
        value: "Melakukan CTG",
        text: "Melakukan CTG"
      },
      {
        value: "Memfasilitasi pasien untuk pemeriksaan radiologi",
        text: "Memfasilitasi pasien untuk pemeriksaan radiologi"
      },
      {
        value: "Free Text",
        text: "Free Text"
      },
    ],
    tindakLanjutUGDDokter: [{
        value: "RI",
        text: "Rawat Inap"
      },
      {
        value: "RU",
        text: "Rujuk"
      },
      {
        value: "RJ",
        text: "Rawat Jalan"
      },
      {
        value: "O",
        text: "Observasi"
      },
      {
        value: "P",
        text: "Melahirkan"
      },
    ],
    layakTidakLayakOpt: [{
        value: "L",
        text: "Layak"
      },
      {
        value: "TL",
        text: "Tidak Layak"
      },
    ],
    tindakLanjutFarmasi: [{
        value: "Lanjut, aturan pakai sama (LS)",
        text: "Lanjut, aturan pakai sama (LS)"
      },
      {
        value: "Lanjut, aturan pakai berubah (LB)",
        text: "Lanjut, aturan pakai berubah (LB)"
      },
      {
        value: "Stop (S)",
        text: "Stop (S)"
      },
      {
        value: "Ganti (G)",
        text: "Ganti (G)"
      },
    ],

    rujukanMasukStatus: [{
        text: "Menunggu",
        value: "M"
      },
      {
        text: "Diterima",
        value: "A"
      },
      {
        text: "Ditolak",
        value: "T"
      },
    ],
    rujukanKeluarStatus: [{
        text: "Menunggu",
        value: "M"
      },
      {
        text: "Menunggu Response",
        value: "MR"
      },
      {
        text: "Diterima",
        value: "A"
      },
      {
        text: "Ditolak",
        value: "T"
      },
    ],
    responRujukan: [{
        text: "Terima",
        value: "A"
      },
      {
        text: "Tolak",
        value: "T"
      },
    ],
    alasanRujukan: [{
        "value": "1",
        "text": "Penanganan / perawatan lanjut pasien (medik)"
      },
      {
        "value": "2a",
        "text": "Sarana dan prasarana (Ruang Perawatan Biasa)"
      },
      {
        "value": "2b",
        "text": "Sarana dan prasarana (Ruang intensive ( ICU, CVCU/ICCU, NICU, PICU, HCU))"
      },
      {
        "value": "2c",
        "text": "Sarana dan prasarana (Ruang Isolasi)"
      },
      {
        "value": "3",
        "text": "Dokter spesialis / sub spesialis tidak tersedia"
      },
      {
        "value": "4",
        "text": "Tindakan khusus"
      }
    ],

    "nilaiKekuatan": [
      {
        "text": "Tidak ada kontraksi otot sama sekali.",
        "value": "0"
      },
      {
        "text": "Kontraksi	otot	minimal	terasa/teraba	pada	otot bersangkutan tanpa menimbulkan gerakan.",
        "value": 1
      },
      {
        "text": "dapat menggerakkan anggota gerak tetatpi tidak dapat melawan gravitasi (menggeser anggota gerak).",
        "value": 2
      },
      {
        "text": "Dapat melakukan ROM secara penuh dengan melawan gaya berat (gravitasi), tetapi tidak dapat melawan tahanan.",
        "value": 3
      },
      {
        "text": "Dapat melakukan Range Of Motion (ROM) secara penuh dan dapat melawan tahanan ringan",
        "value": 4
      },
      {
        "text": "Kekuatan	otot	normal	dimana	seluruh gerakan dapat dilakukan otot dengan tahanan maksimal dari proses yang dilakukan berulang - ulang tanpa menimbulkan kelelahan.",
        "value": 5
      }
    ],

    Terlaksana: [{
        "text": "Terlaksana",
        "value": 1
      },
      {
        "text": "Belum Terlaksana",
        "value": 0
      },
    ],

    mrKasusPenyakit: [{
        "text": "Internis/Dalam",
        "value": 1
      },
      {
        "text": "Pediatric/Anak",
        "value": 2
      },
      {
        "text": "Obsgyn/Kandungan dan Kebidanan",
        "value": 3
      },
      {
        "text": "Surgery/Bedah",
        "value": 4
      },
      {
        "text": "Syaraf",
        "value": 5
      },
      {
        "text": "Jantung",
        "value": 6
      },
      {
        "text": "Paru-Paru",
        "value": 7
      },
      {
        "text": "Orthopedi/Bedah Tulang",
        "value": 8
      },
      {
        "text": "THT/Telinga Hidung Tenggorokan",
        "value": 9
      },
      {
        "text": "Kulit Kelamin",
        "value": 10
      },
      {
        "text": "Gigi dan Mulut",
        "value": 11
      },
      {
        "text": "Mata",
        "value": 12
      },
      {
        "text": "Urologi",
        "value": 13
      },
    ],

    KontrolRujuk: [{
        "text": "Rujukan",
        "value": "Y"
      },
      {
        "text": "Kontrol",
        "value": "N"
      },
    ],

    triasePenempatan: [{
        "text": "Ruang Resusitasi",
        "value": "Ruang Resusitasi"
      },
      {
        "text": "Ruang Observasi",
        "value": "Ruang Observasi"
      },
      {
        "text": "Ruang Tindakan",
        "value": "Ruang Tindakan"
      },
      {
        "text": "Ruang Isolasi",
        "value": "Ruang Isolasi"
      }
    ],

    triaseSkrining: [{
        "text": "Preventif",
        "value": "Preventif"
      },
      {
        "text": "Paliatif",
        "value": "Paliatif"
      },
      {
        "text": "Kuratif",
        "value": "Kuratif"
      },
      {
        "text": "Rehabilitatif",
        "value": "Rehabilitatif"
      }
    ],


    typeICU: [{
        "text": "ICU",
        "value": "ICU"
      },
      {
        "text": "PICU/NICU",
        "value": "PCNC"
      },
    ],
    icuTTV: [{
        "text": "Nadi < 40 kali/menit, >150 kali/menit",
        "value": "Nadi < 40 kali/menit, >150 kali/menit"
      },
      {
        "text": "TD sistolik arteri < 80 mmHg atau 20 mmHg dibawah TD pasien sehari-hari",
        "value": "TD sistolik arteri < 80 mmHg atau 20 mmHg dibawah TD pasien sehari-hari"
      },
      {
        "text": "Map < 60mmHg",
        "value": "Map < 60mmHg"
      },
      {
        "text": "TD diastolic arteri >120mmHg dengan oedem paru, hipertensi",
        "value": "TD diastolic arteri >120mmHg dengan oedem paru, hipertensi"
      },
      {
        "text": "Pasien dengan gagal nafas, RR > 35 x/menit",
        "value": "Pasien dengan gagal nafas, RR > 35 x/menit"
      }
    ],
    icuNilaiLab: [{
        "text": "Natrium serum <110 mEq/L/ >170mEq/L",
        "value": "Natrium serum <110 mEq/L/ >170mEq/L"
      },
      {
        "text": "Kalium serum <2.0 mEq/L / 7.0 mEq/L",
        "value": "Kalium serum <2.0 mEq/L / 7.0 mEq/L"
      },
      {
        "text": "GDS >800 mg/dl",
        "value": "GDS >800 mg/dl"
      },
      {
        "text": "Kalsium serum > 15mg/dl",
        "value": "Kalsium serum > 15mg/dl"
      }
    ],
    icuNilaiRadiologi: [{
        "text": "Perdarahan vaskuler otak, kontusio atau perdarahan subarachnoid dengan penurunan kesadaran atau tanda defisit neurologis fokal",
        "value": "Perdarahan vaskuler otak, kontusio atau perdarahan subarachnoid dengan penurunan kesadaran atau tanda defisit neurologis fokal"
      },
      {
        "text": "Ruptur organ dalam, kandung kemih, hepar,varises esophagusatau uterus dengan hemodinamik tidak stabil",
        "value": "Ruptur organ dalam, kandung kemih, hepar,varises esophagusatau uterus dengan hemodinamik tidak stabil"
      },
      {
        "text": "Diseksi aneurisma aorta",
        "value": "Diseksi aneurisma aorta"
      },
    ],
    icuPemeriksaanFisik: [{
        "text": "Pupil anisokor pada pasien tidak sadar",
        "value": "Pupil anisokor pada pasien tidak sadar"
      },
      {
        "text": "Luka besar >10% BSA",
        "value": "Luka besar >10% BSA"
      },
      {
        "text": "Anuria",
        "value": "Anuria"
      },
      {
        "text": "Obstruksi jalan nafas",
        "value": "Obstruksi jalan nafas"
      },
      {
        "text": "Koma",
        "value": "Koma"
      },
      {
        "text": "Kejang berlanjut",
        "value": "Kejang berlanjut"
      },
      {
        "text": "Sianosis",
        "value": "Sianosis"
      },
      {
        "text": "Temponade jantung",
        "value": "Temponade jantung"
      },
    ],

    nicuNilaiTTV: [{
        "text": "Nadi <80 atau >180 x/menit",
        "value": "Nadi <80 atau >180 x/menit"
      },
      {
        "text": "Tekanan darah sistolik  arteri <50 mmHg atau 20 mmHg dibawah tekanan darah normal bayi menurut masa gestasi",
        "value": "Tekanan darah sistolik  arteri <50 mmHg atau 20 mmHg dibawah tekanan darah normal bayi menurut masa gestasi"
      },
      {
        "text": "Frekuensi nafas <30  atau >90 x/menit",
        "value": "Frekuensi nafas <30  atau >90 x/menit"
      },
    ],

    nicuNilaiRadiografi: [{
        "text": "Perdarahan intracranial dengan penurunan kesadaran atau tanda deficit neurologis",
        "value": "Perdarahan intracranial dengan penurunan kesadaran atau tanda deficit neurologis"
      },
      {
        "text": "Hernia diafragma",
        "value": "Hernia diafragma"
      }
    ],

    configStatusRekonsil: [{
        text: 'ALL',
        value: 'ALL'
      },
      {
        text: 'MENUNGGU',
        value: 'N'
      },
      {
        text: 'SELESAI',
        value: 'Y'
      }
    ],

    configStatusKajianUGD: [{
        text: 'Semua Status',
        value: 'ALL'
      },
      {
        text: 'Triase',
        value: '1'
      },
      {
        text: 'Dalam Pengkajian',
        value: '2'
      },
      {
        text: 'Keluar UGD',
        value: '3'
      },
      {
        text: 'Selesai',
        value: 'selesai'
      }
    ],

    configStatusKajianUGD2: [{
        text: 'Semua Status',
        value: 'ALL'
      },
      {
        text: 'Dalam Pengkajian',
        value: '2'
      },
      {
        text: 'Keluar UGD',
        value: '3'
      },
      {
        text: 'Selesai',
        value: 'selesai'
      }
    ],

    waktuPemberian: [{
        text: 'Pagi',
        value: 'Pagi'
      },
      {
        text: 'Siang',
        value: 'Siang'
      },
      {
        text: 'Malam',
        value: 'Malam'
      }
    ],

    statusCheckin: [{
        text: "Semua Status",
        value: "ALL"
      },
      {
        text: "Belum Checkin",
        value: "N"
      },
      {
        text: "Sudah Checkin",
        value: "Y"
      }
    ],


    statusBahasaRanap: [{
        text: "Indonesia",
        value: "Indonesia"
      },
      {
        text: "Inggris",
        value: "Inggris"
      },
      {
        text: "Daerah",
        value: "Daerah"
      },
      {
        text: "Lain-lain",
        value: "Lainnya"
      },
    ],

    statusTipePembelajaran: [{
        text: "Verbal",
        value: "Verbal"
      },
      {
        text: "Tulisan",
        value: "Tulisan"
      }
    ],

    statusBacaTulisRanap: [{
        text: "Baik",
        value: "Baik"
      },
      {
        text: "Kurang",
        value: "Kurang"
      },

      {
        text: "Tidak bisa membaca / Buta Huruf",
        value: "Tidak bisa membaca / Buta Huruf"
      },
    ],

    materiEdukasi: [
      {
        text: "Hak dan Kewajiban, tata tertib RS",
        value: "Hak dan Kewajiban, tata tertib RS"
      }, {
        text: "Manfaat Asuransi",
        value: "Manfaat Asuransi"
      }, {
        text: "Pertanyaan Tambahan",
        value: "Pertanyaan Tambahan"
      }
    ],

    hambatanEdukasi: [{
        text: "Tidak Ada",
        value: "Tidak Ada"
      },
      {
        text: "Emosional: Marah, Cemas, Sedih",
        value: "Emosional: Marah, Cemas, Sedih"
      },
      {
        text: "Motivasi: Tidak ada motivasi belajar",
        value: "Motivasi: Tidak ada motivasi belajar"
      },
      {
        text: "Keterbatasan kognitif (cacat fisik atau mental)",
        value: "Keterbatasan kognitif (cacat fisik atau mental)"
      },
    ],

    reservasiKetersediaan: [{
        text: "Bersedia",
        value: "Y"
      },
      {
        text: "Tidak Bersedia",
        value: "N"
      },
    ],

    resRanapMateriEdukasi: [{
        text: "Hak dan kewajiban, tata tertib RS",
        value: "Hak dan kewajiban, tata tertib RS"
      },
      {
        text: "Manfaat Asuransi",
        value: "Manfaat Asuransi"
      }
    ],

    resRanapMetodeEdukasi: [{
        text: "Wawancara",
        value: "Wawancara"
      },
      {
        text: "Diskusi Kelompok",
        value: "Diskusi Kelompok"
      },
      {
        text: "Ceramah",
        value: "Ceramah"
      },
      {
        text: "Demonstrasi",
        value: "Demonstrasi"
      },
    ],

    resRanapSaranaEdukasi: [{
        text: "Leaflet",
        value: "Leaflet"
      },
      {
        text: "Booklet",
        value: "Booklet"
      },
      {
        text: "Lembar Balik",
        value: "Lembar Balik"
      },
      {
        text: "Audio Visual",
        value: "Audio Visual"
      }
    ],

    resRanapSaranaEdukator: [{
        text: "Re-edukasi",
        value: "Re-edukasi"
      },
      {
        text: "Re-demonstrasi",
        value: "Re-demonstrasi"
      },
      {
        text: "Sudah mengerti",
        value: "Sudah mengerti"
      }
    ],

    resRanapTingkatPemahaman: [{
        text: "Sudah Mengerti",
        value: "Sudah Mengerti"
      },
      {
        text: "Edukasi Ulang",
        value: "Edukasi Ulang"
      },
    ],

    jenisRawatInap: [{
        text: "Obsgyn",
        value: 1
      },
      {
        text: "Perinatologi",
        value: 2
      },
      {
        text: "Umum",
        value: 3
      },
      {
        text: "Kritis",
        value: 4
      },
    ],

    ranapStatusMental: [{
        text: "Sadar dan orientasi penuh",
        value: "Sadar dan orientasi penuh"
      },
      {
        text: "Ada kelainan perilaku, sebutkan",
        value: "Ada kelainan perilaku, sebutkan"
      },
      {
        text: "Ada perilaku kekerasan yang dialami pasien sebelumnya",
        value: "Ada perilaku kekerasan yang dialami pasien sebelumnya"
      }
    ],

    ranapMasalahNutrisi: [{
        text: "Nausea",
        value: "Nausea"
      },
      {
        text: "Vomitus",
        value: "Vomitus"
      },
      {
        text: "Malnutrisi",
        value: "Malnutrisi"
      },
      {
        text: "Obesitas",
        value: "Obesitas"
      },
      {
        text: "Sulit Menelan",
        value: "Sulit Menelan"
      },
      {
        text: "Tidak ada Masalah",
        value: "Tidak ada Masalah"
      },
    ],

    ranapMasalahBAB: [{
        text: "Normal",
        value: "Normal"
      },
      {
        text: "Konstipasi",
        value: "Konstipasi"
      },
      {
        text: "Diare",
        value: "Diare"
      },
      {
        text: "Lain-Lain",
        value: "L"
      },
    ],

    ranapMasalahBAK: [{
        text: "Normal",
        value: "Normal"
      },
      {
        text: "Hematuri",
        value: "Hematuri"
      },
      {
        text: "Disuria",
        value: "Disuria"
      },
      {
        text: "Urin Menetes",
        value: "Urin Menetes"
      },
      {
        text: "Inkontinensia",
        value: "Inkontinensia"
      },
      {
        text: "Nokturia/Sering kencing malam hari",
        value: "Nokturia/Sering kencing malam hari"
      },
      {
        text: "Lain-Lain",
        value: "L"
      },
    ],

    ranapMasalahKateter: [{
        text: "Ya",
        value: "Ya"
      },
      {
        text: "Tidak",
        value: "Tidak"
      },
      {
        text: "Lain-Lain",
        value: "L"
      },
    ],

    ranapKPStatusMental: [{
        text: "Tidak Ada",
        value: "Tidak Ada"
      },
      {
        text: "Agitasi",
        value: "Agitasi"
      },
      {
        text: "Menyerang",
        value: "Menyerang"
      },
      {
        text: "Kooperatif",
        value: "Kooperatif"
      },
      {
        text: "Disorientasi Orang",
        value: "Disorientasi Orang"
      },
      {
        text: "Disorientasi Tempat",
        value: "Disorientasi Tempat"
      },
      {
        text: "Disorientasi Waktu",
        value: "Disorientasi Waktu"
      },
      {
        text: "Letargi",
        value: "Letargi"
      },
      {
        text: "Kejang",
        value: "Kejang"
      },
      {
        text: "Lain-lain",
        value: "L"
      }
    ],

    isRestrain: [{
        text: "Tidak Ada Masalah yang teridentifikasi restrain",
        value: "N"
      },
      {
        text: "Ada Masalah yang teridentifikasi restrain",
        value: "Y"
      },
    ],

    ranapKPRiwayatJatuh: [{
        text: "Ada Riwayat Jatuh",
        value: "Ada Riwayat Jatuh"
      },
      {
        text: "Sulit berjalan",
        value: "Sulit berjalan"
      },
      {
        text: "Gerakan yang terbatas",
        value: "Gerakan yang terbatas"
      },
      {
        text: "Gangguan muscoskeletal",
        value: "Gangguan muscoskeletal"
      },
      {
        text: "Tidak Ada",
        value: "Tidak Ada"
      },
      {
        text: "Lain-Lain",
        value: "L"
      },
    ],

    ranapResHasilObservasi: [{
        text: "Pasien gelisah atau dilirium dan berontak",
        value: "Pasien gelisah atau dilirium dan berontak"
      },
      {
        text: "Pasien tidak kooperatif",
        value: "Pasien tidak kooperatif"
      },
      {
        text: "Ketidak mampuan dalam mengikuti perintah untuk tidak meninggalkan tempat tidur",
        value: "Ketidak mampuan dalam mengikuti perintah untuk tidak meninggalkan tempat tidur"
      },
    ],

    ranapResPertimbanganKlinis: [{
        text: "Membahayakan diri sendiri",
        value: "Membahayakan diri sendiri"
      },
      {
        text: "Membahayakan orang lain",
        value: "Membahayakan orang lain"
      },
    ],

    ranapResPenilaianOrder: [{
        text: "Restrain Non Farmakologi",
        value: "Restrain Non Farmakologi"
      },
      {
        text: "Membahayakan diri sendiri",
        value: "Membahayakan diri sendiri"
      },
      {
        text: "Membahayakan orang lain",
        value: "Membahayakan orang lain"
      },
      {
        text: "Restrain Farmakologi",
        value: "Restrain Farmakologi"
      },
    ],


    ranapKAGangguanHaid: [{
        text: "Tidak",
        value: "Tidak"
      },
      {
        text: "Dismenorrhea",
        value: "Dismenorrhea"
      },
      {
        text: "Spotting",
        value: "Spotting"
      },
      {
        text: "Menorraghia",
        value: "Menorraghia"
      },
      {
        text: "Metrorraghia",
        value: "Metrorraghia"
      },
      {
        text: "Pre Menstruasi Syndrome",
        value: "Pre Menstruasi Syndrome"
      },
    ],
    ranapKAInspeksi: [{
        text: "Membesar dengan arah memanjang",
        value: "Membesar dengan arah memanjang"
      },
      {
        text: "Melebar",
        value: "Melebar"
      },
      {
        text: "Pelebaran Vena",
        value: "Pelebaran Vena"
      },
      {
        text: "Linea alba",
        value: "Linea alba"
      },
      {
        text: "Linea Nigra",
        value: "Linea Nigra"
      },
      {
        text: "Striae Albican",
        value: "Striae Albican"
      },
      {
        text: "Striae Livide",
        value: "Striae Livide"
      },
      {
        text: "Luka Bekas operasi",
        value: "Luka Bekas operasi"
      },
      {
        text: "Lain-Lain",
        value: "Lainnya"
      },
    ],
    ranapKALetakPunggung: [{
        text: "Puka",
        value: "Puka"
      },
      {
        text: "Puki",
        value: "Puki"
      },
    ],
    ranapKAPresentasi: [{
        text: "Bokong",
        value: "Bokong"
      },
      {
        text: "Lintang",
        value: "Lintang"
      },
      {
        text: "Kepala",
        value: "Kepala"
      },
    ],
    ranapKAjmlNikah: [{
        text: "1 Kali",
        value: "1 Kali"
      },
      {
        text: "2 Kali",
        value: "2 Kali"
      },
      {
        text: "Lebih",
        value: "Lebih"
      },
    ],
    ranapKAKomplikasi: [{
        text: "Perdarahan",
        value: "Perdarahan"
      },
      {
        text: "PID / Radang panggul",
        value: "PID / Radang panggul"
      },
      {
        text: "Tidak Ada",
        value: "Tidak Ada"
      },
    ],
    ranapKAMasukPanggul: [{
        text: "N 13 ( Kepala Belum Melewati Pintu Atas Panggul)",
        value: 13
      },
      {
        text: "N 12 ( Kepala Masih berada diatas Spina Ischiadika)",
        value: 12
      },
      {
        text: "N 11 ( Kepala Masih dibawah Spina Ischiadika)",
        value: 11
      },
    ],
    ranapTypePersalinan: [{
        text: "Spontan",
        value: "Spontan"
      },
      {
        text: "Induksi",
        value: "Induksi"
      },
      {
        text: "Forcep",
        value: "Forcep"
      },
      {
        text: "Vacum",
        value: "Vacum"
      },
      {
        text: "Sectio Caesarea",
        value: "Sectio Caesarea"
      },
    ],
    ranapTypeImunisasi: [{
      text: "Lengkap",
      value: "Lengkap"
    }, {
      text: "Tidak Pernah",
      value: "Tidak Pernah"
    }, {
      text: "Tidak lengkap",
      value: "Tidak lengkap"
    }, ],
    ranapKNFrNyeri: [{
        text: "Jarang",
        value: "Jarang"
      },
      {
        text: "Hilang Timbul",
        value: "Hilang Timbul"
      },
      {
        text: "Terus menerus",
        value: "Terus menerus"
      },
    ],
    ranapKNMenjalar: [{
      text: "Tidak",
      value: "N"
    }, {
      text: "Ya, Ke:",
      value: "Y"
    }, ],
    ranapKNKualitasNyeri: [{
        text: "Nyeri Tumpul",
        value: "Nyeri Tumpul"
      },
      {
        text: "Nyeri Tajam",
        value: "Nyeri Tajam"
      },
      {
        text: "Panas/Terbakar",
        value: "Panas/Terbakar"
      },
    ],
    ranapKNTindakLanjut: [{
      text: "Konsul Dokter",
      value: "Konsul Dokter"
    }, {
      text: "Edukasi",
      value: "Edukasi"
    }, ],
    ranapDataPupil: [{
      text: "Isokor",
      value: "Isokor"
    }, {
      text: "Anisokor",
      value: "Anisokor"
    }],
    configPucatDingin: [{
        text: "Ya",
        value: "Ya"
      },
      {
        text: "Tidak",
        value: "Tidak"
      },
    ],
    configSianosi: [{
        text: "< 2 detik",
        value: "< 2 detik"
      },
      {
        text: "> 2 detik",
        value: "> 2 detik"
      },
    ],
    configKelembapanKulit: [{
        text: "Lembap",
        value: "Lembap"
      },
      {
        text: "Kering",
        value: "Kering"
      },
    ],
    configTurgor: [{
        text: "Lembap",
        value: "Lembap"
      },
      {
        text: "Kering",
        value: "Kering"
      },
    ],

    configTeratur: [{
        text: "Teratur",
        value: "Teratur"
      },
      {
        text: "Tidak teratur",
        value: "Tidak teratur"
      },
    ],

    configPemeriksaanANC: [{
        text: "Dokter umum/spesialis",
        value: "Dokter umum/spesialis"
      },
      {
        text: "Bidan",
        value: "Bidan"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      },
    ],

    configKomplikasiANC: [{
        text: "Diabetes Mellitus",
        value: "Diabetes Mellitus"
      },
      {
        text: "Jantung",
        value: "Jantung"
      },
      {
        text: "Tuberculosa",
        value: "Tuberculosa"
      },
      {
        text: "Paru",
        value: "Paru"
      },
      {
        text: "Hepatitis B",
        value: "Hepatitis B"
      },
      {
        text: "Asma",
        value: "Asma"
      },
      {
        text: "Penyakit menular Seksual",
        value: "Penyakit menular Seksual"
      },
      {
        text: "Alergi",
        value: "Alergi"
      },
      {
        text: "Tidak Ada",
        value: "Tidak Ada"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      },
    ],
    configPengobatanIbu: [
      {
        text: "Spontan",
        value: "Spontan"
      },
      {
        text: "Section",
        value: "Section"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      },
    ],
    configMasalah: [{
        text: "Tidak Ada Masalah",
        value: "N"
      },
      {
        text: "Ada Masalah",
        value: "Y"
      },
    ],
    mApgar_appereance: [{
        "value": 1,
        "text": "Seluruh tubuh biru / pucat"
      },
      {
        "value": 2,
        "text": "Tubuh kemerahan, ekstermitas biru"
      },
      {
        "value": 3,
        "text": "Seluruh tubuh kemerahan"
      }
    ],
    mApgar_pulse: [{
        "value": 1,
        "text": "Tidak ada"
      },
      {
        "value": 2,
        "text": "Kurang dari 100 x/menit"
      },
      {
        "value": 3,
        "text": "Lebih dari 100 x/menit"
      }
    ],
    mApgar_grimace: [
      {
        "value": 1,
        "text": "Tidak bereaksi"
      }, {
        "value": 2,
        "text": "Gerakan sedikit"
      }, {
        "value": 3,
        "text": "Reaksi melawan"
      }
    ],
    mApgar_activity: [
      {
        "value": 1,
        "text": "Lumpuh"
      }, {
        "value": 2,
        "text": "Ekstermitas fleksisedikit"
      }, {
        "value": 3,
        "text": "Gerakan aktif"
      }
    ],
    mApgar_respiration: [
      {
        "value": 1,
        "text": "Tidak ada"
      }, {
        "value": 2,
        "text": "Lambat"
      }, {
        "value": 3,
        "text": "Menangis Kuat"
      }
    ],

    mHubunganRanap : [{
       "value": "Menyentuh",
       "text": "Menyentuh"
     }, {
       "value": "Memeluk",
       "text": "Memeluk"
     }, {
       "value": "Berbicara",
       "text": "Berbicara"
     }, {
       "value": "Berkunjung",
       "text": "Berkunjung"
     }, {
       "value": "Kontak Mata",
       "text": "Kontak Mata"
     }, {
       "value": "Memanggil Nama",
       "text": "Memanggil Nama"
     }],

    mRKDiperlukanDlmHal : [
      {
        "text": "Keluarga menginginkan adanya konseling spiritual/Agama",
        "value": "Keluarga menginginkan adanya konseling spiritual/Agama"
      },
      {
        "text": "Keluarga membutuhkan bantuan dalam menjalankan ibadah",
        "value": "Keluarga membutuhkan bantuan dalam menjalankan ibadah"
      },
      {
        "text": "Tidak Ada",
        "value": "Tidak Ada"
      },      
    ],
    
    mBantuanDiperlukanDalamHal : [{
        "value": "Minum obat",
        "text": "Minum obat"
      },
      {
        "value": "Makan",
        "text": "Makan"
      },
      {
        "value": "Menyiapkan makanan",
        "text": "Menyiapkan makanan"
      },
      {
        "value": "Transportasi",
        "text": "Transportasi"
      },
      {
        "value": "Diet",
        "text": "Diet"
      },
      {
        "value": "Mandi",
        "text": "Mandi"
      },
      {
        "value": "Berpakaian",
        "text": "Berpakaian"
      }
    ],

    mConfigRiwayat: [
      {
        "value": "N",
        "text": "Sehat"
      },
      {
        "value": "Y",
        "text": "Ada Riwayat Penyakit"
      },
    ],

    mConfigNormal: [
      {
        "value": "N",
        "text": "Normal"
      },
      {
        "value": "TN",
        "text": "Tidak Normal"
      },
    ],

    mZerotoTwo: [
      {
        "value": 0,
        "text": 0
      }, 
      {
        "value": 1,
        "text": 1
      },
      {
        "value": 2,
        "text": 2
      }
    ],

    mRanapBBLKeadaanUmum: [
      {
        "value": "Baik",
        "text": "Baik"
      }, {
        "value": "Lemah",
        "text": "Lemah"
      }, {
        "value": "Meninggal",
        "text": "Meninggal"
      },
    ],
    
    mSudahBelum: [
      {
        "value": "Sudah",
        "text": "Sudah"
      }, {
        "value": "Belum",
        "text": "Belum"
      }
    ],

    mAnusLubang: [
      {
        "value": "Berlubang",
        "text": "Berlubang"
      }, {
        "value": "Tertutup",
        "text": "Tertutup"
      }
    ],

    mTerapiBBL: [
      {
        "value": "Zalf Mata",
        "text": "Zalf Mata",
      },
      {
        "value": "Vitadion 1mg IM",
        "text": "Vitadion 1mg IM",
      },
    ],

    mRiwayatIntraNatalJenisPersalinan: [
      {
        "value": "SC",
        "text": "SC"
      }, {
        "value": "Spontan",
        "text": "Spontan"
      }, {
        "value": "Vacum",
        "text": "Vacum"
      }, {
        "value": "Lain-lain",
        "text": "Lain-lain"
      },
    ],
    
    mRiwayatIntraNatalPenolong: [
      {
        "value": "Dokter",
        "text": "Dokter"
      }, {
        "value": "Bidan",
        "text": "Bidan"
      }, {
        "value": "Lain-lain",
        "text": "Lain-lain"
      }
    ],

    mCaraLahir: [
      {
        "value": "Sectio Caesarea",
        "text": "Sectio Caesarea"
      },
      {
        "value": "Spontan",
        "text": "Spontan"
      },
    ],

    mRanapBBLMedisKesan: [
      {
        "value": "Gerak Aktif",
        "text": "Gerak Aktif"
      },
      {
        "value": "Tidak Aktif",
        "text": "Tidak Aktif"
      },
    ],

    mRanapBBLMedisWarna: [
      {
        "value": "Kemerahan",
        "text": "Kemerahan"
      },
      {
        "value": "Kebiruan",
        "text": "Kebiruan"
      },
      {
        "value": "Pucat",
        "text": "Pucat"
      },
    ],
    
    mRanapBBLMedisNafas: [
      {
        "value": "Sesak",
        "text": "Sesak"
      },
      {
        "value": "Tidak Sesak",
        "text": "Tidak Sesak"
      }
    ],
    
    mRanapBBLMedisTonus: [
      {
        text: 'Baik',
        value: 'Baik'
      },
      {
        text: 'Lemah',
        value: 'Lemah'
      }
    ],

    mRanapBBLBentuk: [
      { text: "Normocephal", value: "Normocephal" },
      { text: "Brachicephal", value: "Brachicephal" },
    ],
    mRanapBBLFontanel: [
      { text: "Cekung", value: "Cekung" },
      { text: "Cembung", value: "Cembung" },
      { text: "Datar", value: "Datar" },
    ],
    mNormalTidakNormalText: [
      { text: "Normal", value: "Normal" },
      { text: "Tidak Normal", value: "Tidak Normal" },
    ],
    mAdaTidakAdaText: [
      { text: "Tidak Ada", value: "Tidak Ada" },
      { text: "Ada", value: "Ada" },
    ],
    mRanapBBLTelinga: [
      { text: "Normal", value: "Normal" },
      { text: "Malformasi kongenital", value: "Malformasi kongenital" },
    ],
    mRanapBBLHidung: [
      { text: "Normal", value: "Normal" },
      { text: "Nafas cuping hidung", value: "Nafas cuping hidung" },
    ],
    mRanapBBLMulut: [
      { text: "Normal", value: "Normal" },
      { text: "Sianosis", value: "Sianosis" },
    ],
    mRanapBBLBibir: [
      { text: "Normal", value: "Normal" },
      { text: "Cleft", value: "Cleft" },
    ],
    mRanapBBLLidah: [
      { text: "Normal", value: "Normal" },
      { text: "Sianosis", value: "Sianosis" },
    ],
    mRanapBBLLeher: [
      { text: "Normal", value: "Normal" },
      { text: "WebNeck", value: "WebNeck" },
    ],
    mRanapBBLPemeriksaan: [
      { text: "Normal", value: "Normal" },
      { text: "Retraksi Dada", value: "Retraksi Dada" },
    ],
    mRanapBBLParu: [
      { text: "Ada SDV (suara dasar vesikuler)", value: "Ada SDV (suara dasar vesikuler)" },
      { text: "Tidak Ada SDV (suara dasar vesikuler)", value: "Tidak Ada SDV (suara dasar vesikuler)" },
    ],
    mRanapBBLJantung: [
      { text: "Suara Tambahan", value: "Suara Tambahan" },
      { text: "Normal", value: "Normal" },
    ],
    mRanapBBLPerut: [
      { text: "Normal", value: "Normal" },
      { text: "Tidak Normal", value: "Tidak Normal" },
    ],
    mRanapBaikLayu: [
      { text: "Baik", value: "Baik" },
      { text: "Layu", value: "Layu" },
    ],
    mRanapAnggotaGerak: [
      { text: "Baik", value: "Baik" },
      { text: "Kurang", value: "Kurang" },
    ],
    statusGiziRanap: [
      {
        text: "Baik",
        value: "Baik"
      },
      {
        text: "Lebih",
        value: "Lebih"
      },
      {
        text: "Kurang",
        value: "Kurang"
      },
      {
        text: "Obesitas",
        value: "Obesitas"
      },
    ],
    ranapGiziPolaMakan: [{
        text: "3x makan",
        value: "3x makan"
      },
      {
        text: "3x makan + 2x selingan",
        value: "3x makan + 2x selingan"
      },
      {
        text: "makan kurang dari 3x",
        value: "makan kurang dari 3x"
      },
      {
        text: "makan lebih dari 3x",
        value: "makan lebih dari 3x"
      },
      {
        text: "belum makan",
        value: "belum makan"
      },
    ],
    ranapGiziCaraPemberian: [
      {
        text: "Mulut",
        value: "Mulut"
      },
      {
        text: "Pipa",
        value: "Pipa"
      }
    ],
    ranapGiziIntervensiLewatMulut: [{
        text: "biasa",
        value: "biasa"
      },
      {
        text: "lunak",
        value: "lunak"
      },
      {
        text: "saring",
        value: "saring"
      },
      {
        text: "ASI",
        value: "ASI"
      }
    ],
    ranapGiziIntervensiLewatPipa: [{
        text: "cair",
        value: "cair"
      },
      {
        text: "sonde",
        value: "sonde"
      },
      {
        text: "ASI",
        value: "ASI"
      }
    ],
    ranapGiziImplementasi: [{
        text: "Melakukan edukasi gizi pada pasien atau keluarga pasien.",
        value: "Melakukan edukasi gizi pada pasien atau keluarga pasien."
      },
      {
        text: "Koordinasikan dengan petugas kesehatan lain untuk pencatatan asupan makanan.",
        value: "Koordinasikan dengan petugas kesehatan lain untuk pencatatan asupan makanan."
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      }
    ],
    ranapResikoJatuhRendah: [
      {
        text: "Perawatan yang Baik",
        value: "Perawatan yang Baik",
      }
    ],
    ranapResikoJatuhSedang: [
      {
        text: "Tingkatkan observasi bantuan yang sesuai saat ambulasi",
        value: "Tingkatkan observasi bantuan yang sesuai saat ambulasi"
      }, {
        text: "Keselamatan lingkungan: hindari ruangan yang kacau balau, dekatkan bel & telepon, gunakan penerangan yang cukup di malam hari, posisi tempat tidur rendah, terpasang hek bed, serta roda tempat tidur selalu terkunci.",
        value: "Keselamatan lingkungan: hindari ruangan yang kacau balau, dekatkan bel & telepon, gunakan penerangan yang cukup di malam hari, posisi tempat tidur rendah, terpasang hek bed, serta roda tempat tidur selalu terkunci."
      }, {
        text: "Monitor kebutuhan pasien, keluarga harus menemani pasien yang berisiko jatuh, apabila tidak ada keluarga, pasien diminta menekan bel bila membutuhkan bantuan.",
        value: "Monitor kebutuhan pasien, keluarga harus menemani pasien yang berisiko jatuh, apabila tidak ada keluarga, pasien diminta menekan bel bila membutuhkan bantuan."
      }, {
        text: "Edukasi perilaku untuk mencegah jatuh pada pasien dan keluarga dengan menempatkan standing akrilik dimeja samping bed pasien.",
        value: "Edukasi perilaku untuk mencegah jatuh pada pasien dan keluarga dengan menempatkan standing akrilik dimeja samping bed pasien."
      }, {
        text: "Gunakan alat bantu (walker, hand rail)",
        value: "Gunakan alat bantu (walker, hand rail)"
      }, {
        text: "Anjurkan pasien memakai kaos kaki atau sepatu anti slip",
        value: "Anjurkan pasien memakai kaos kaki atau sepatu anti slip"
      }, {
        text: "Lakukan penilaian ulang risiko jatuh, jika ada perubahan kondisi atau pengobatan.",
        value: "Lakukan penilaian ulang risiko jatuh, jika ada perubahan kondisi atau pengobatan."
      }
    ],
    ranapResikoJatuhTinggi: [
      {
        text: "Pasang gelang kuning",
        value: "Pasang gelang kuning"
      },
      {
        text: "Pasang pita kuning pada bed pasien",
        value: "Pasang pita kuning pada bed pasien"
      },
      {
        text: "Lakukan intervensi jatuh standar",
        value: "Lakukan intervensi jatuh standar"
      },
      {
        text: "Strategi mencegah jatuh dengan penilaian jatuh yang lebih detail seperti cara berjalan, sehingga dapat ditentukan intervensi special, seperti menggunakan terapi fisik atau alat bantu jenis terbaru untuk membantu mobilisasi.",
        value: "Strategi mencegah jatuh dengan penilaian jatuh yang lebih detail seperti cara berjalan, sehingga dapat ditentukan intervensi special, seperti menggunakan terapi fisik atau alat bantu jenis terbaru untuk membantu mobilisasi."
      },
      {
        text: "Pasien ditempatkan didekat nurse station",
        value: "Pasien ditempatkan didekat nurse station"
      },
      {
        text: "Siapkan komod dan alat bantu jalan",
        value: "Siapkan komod dan alat bantu jalan"
      },
      {
        text: "Hand rail kokoh dan mudah dijangkau pasien",
        value: "Hand rail kokoh dan mudah dijangkau pasien"
      },
      {
        text: "Lantai kamar mandi dengan karpet anti slip, serta anjuran pasien menggunakan kursi saat mandi",
        value: "Lantai kamar mandi dengan karpet anti slip, serta anjuran pasien menggunakan kursi saat mandi"
      },
      {
        text: "Dampingi pasien dikamar mandi, informasikan cara pakai bel ditoilet, pintu jangan dikunci",
        value: "Dampingi pasien dikamar mandi, informasikan cara pakai bel ditoilet, pintu jangan dikunci"
      },
      {
        text: "Lakukan penilaian setiap shift",
        value: "Lakukan penilaian setiap shift"
      }
    ],

    ranapKAFisikKepala: [{
        text: "Fontanel Anterior",
        value: "Fontanel Anterior"
      },
      {
        text: "Sutura sagitalis",
        value: "Sutura sagitalis"
      },
      {
        text: "Caput succedaneum",
        value: "Caput succedaneum"
      },
      {
        text: "Cepalhematom",
        value: "Cepalhematom"
      }
    ],

    ranapKAFisikMata: [{
        text: "Sklera iketrik",
        value: "Sklera iketrik"
      },
      {
        text: "Perdarahan",
        value: "Perdarahan"
      }
    ],

    ranapKAFisikHidung: [{
        text: "Simetris",
        value: "Simetris"
      },
      {
        text: "Asimetris",
        value: "Asimetris"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      }
    ],

    ranapKAFisikMulut: [{
        text: "Normal",
        value: "Normal"
      },
      {
        text: "Bibir sumbing",
        value: "Bibir sumbing"
      },
      {
        text: "Asimetris",
        value: "Asimetris"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      }
    ],

    ranapKAFisikMulutMukosa: [{
        text: "Lembab",
        value: "Lembab"
      },
      {
        text: "Asimetris",
        value: "Asimetris"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      }
    ],

    ranapKAFisikDada: [{
        text: "Simetris",
        value: "Simetris"
      },
      {
        text: "Asimetris",
        value: "Asimetris"
      },
      {
        text: "Retraksi",
        value: "Retraksi"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      }
    ],

    ranapKAWarnaKulit: [
      {
        text: "Merah Muda",
        value: "Merah Muda"
      }, {
        text: "Pucat",
        value: "Pucat"
      }, {
        text: "Kuning",
        value: "Kuning"
      }, {
        text: "Mottled",
        value: "Mottled"
      }
    ],

    plusMin: [{
      text: "+",
      value: "+"
    }, {
      text: "-",
      value: "-"
    }],

    ranapPupilDiameter: [
      {
        text: "1mm",
        value: "1mm"
      }, 
      {
        text: "2mm",
        value: "2mm"
      },
      {
        text: "3mm",
        value: "3mm"
      },
      {
        text: "4mm",
        value: "4mm"
      },
      {
        text: "5mm",
        value: "5mm"
      },
    ],

    tindakanAnestesi: [
      {
        text: "General Anestesi",
        value: "General Anestesi"
      },
      {
        text: "Spinal",
        value: "Spinal"
      },
      {
        text: "Epidural",
        value: "Epidural"
      },
      {
        text: "Lokal",
        value: "Lokal"
      },
    ],

    mAnasUmum : [{
        text: "Intravena",
        value: "Intravena"
      },
      {
        text: "Sungkup Muka",
        value: "Sungkup Muka"
      },
      {
        text: "Laringeal Mask Airway",
        value: "Laringeal Mask Airway"
      },
      {
        text: "Pipa Endotracheal",
        value: "Pipa Endotracheal"
      }
    ],

    mAnasRegional : [{
        text: "Spinal Anestesi",
        value: "Spinal Anestesi"
      },
      {
        text: "Epidural",
        value: "Epidural"
      },
      {
        text: "Kombinasi Spinal Epidural",
        value: "Kombinasi Spinal Epidural"
      },
      {
        text: "Peripheral blok",
        value: "Peripheral blok"
      }
    ],

    mTipeAnestesi : [{
        text: "Anestesi Umum",
        value: "Anestesi Umum"
      },
      {
        text: "Regional Anestesi",
        value: "Regional Anestesi"
      },
      {
        text: "Anestesi Umum + Regional Anestesi",
        value: "Anestesi Umum + Regional Anestesi"
      },
      {
        text: "Sedasi Sedang",
        value: "Sedasi Sedang"
      },
      {
        text: "Sedasi Dalam",
        value: "Sedasi Dalam"
      }
    ],

    mDiagnosisASA:[
      {
        text: "ASA 1 - Seorang pasien normal dan sehat , tidak ada penyakit organ", 
        value : "ASA 1"
      },
      {
        text: "ASA 2 - Seorang pasien dengan penyakit sistemik ringan, atau sedang tanpa gangguan",
        value: "ASA 2"
      },
      {
        text: "ASA 3 - Seorang pasien dengan penyakit sistemik berat atau dengan gangguan fungsional definitif",
        value: "ASA 3"
      },
      {
        text: "ASA 4 - Pasien yang memiliki penyakit sistemik berat yang mengancam nyawa seperti iskemia jantung, syok,sepsis dan yang lainnya",
        value: "ASA 4"
      },
      {
        text: "ASA 5 - Pasien dengan kondisi kritis yang diperkirakan tidak bertahan tanpa pembedahan",
        value: "ASA 5"
      }
    ],

    posisiPasienRanap: [
      { text: "Supinasi", value: "Supinasi" },
      { text: "Pronasi", value: "Pronasi" },
      { text: "Lateral Kanan/Kiri", value: "Lateral Kanan/Kiri" },
      { text: "Litotomi", value: "Litotomi" },
      { text: "Sim Kanan/Kiri", value: "Sim Kanan/Kiri" },
      { text: "Lainnya", value: "Lainnya" }
    ],
    jamOrMenit: [
      {
        text: "Jam",
        value: "JAM"
      },{
        text: "Menit",
        value: "MENIT"
      }
    ],
    macamOperasi: [
      { text: "Besar", value: "Besar" },
      { text: "Sedang", value: "Sedang" },
      { text: "Kecil", value: "Kecil" },
      { text: "Khusus", value: "Khusus" },
    ],
    kegawatanOperasi: [
      { text: "Elektif", value: "Elektif" },
      { text: "Cito", value: "Cito" },
    ],
    ranapLaporanKontrol: [
      { text: "Tensi", value: "Tensi" },
      { text: "Puasa", value: "Puasa" },
      { text: "Infus", value: "Infus" },
      { text: "Antibiotika", value: "Antibiotika" },
    ],
    ranapStatusMppMampu: [
      { text: "Mampu", value: "MAMPU" },
      { text: "Tidak Mampu", value: "TIDAK_MAMPU" },
    ],
    ranapJenisOperasi: [{
        text: "CITO",
        value: "CITO"
      },
      {
        text: "Elektif",
        value: "Elektif"
      }
    ],

    mLaporanAnestesiUmumInhalasi: [{
        text: "Semi Closed",
        value: "Semi Closed"
      },
      {
        text: "Semi Open",
        value: "Semi Open"
      },
      {
        text: "Closed",
        value: "Closed"
      }
    ],

    mLaporanAnestesiIV: [{
        text: "Drip",
        value: "Drip"
      },
      {
        text: "Intermitten",
        value: "Intermitten"
      }
    ],

    mLaporanAnestesiRegional: [{
        text: "Spinal",
        value: "Spinal"
      },
      {
        text: "Epidural",
        value: "Epidural"
      },
      {
        text: "Blok Perifer",
        value: "Blok Perifer"
      }
    ],

    mLaporanAnestesiLevel: [{
        text: "Median",
        value: "Median"
      },
      {
        text: "Paramedian",
        value: "Paramedian"
      }
    ],

    mLaporanAnestesiMaskerDengan: [{
        text: "Mesin",
        value: "Mesin"
      },
      {
        text: "Tangan",
        value: "Tangan"
      }
    ],

    mLaporanAnestesiPosisi: [{
        text: "Terlentang",
        value: "Terlentang"
      },
      {
        text: "Tengkurap",
        value: "Tengkurap"
      },
      {
        text: "Litotomi",
        value: "Litotomi"
      },
      {
        text: "Miring Kanan",
        value: "Miring Kanan"
      },
      {
        text: "Miring Kiri",
        value: "Miring Kiri"
      },
      {
        text: "Duduk",
        value: "Duduk"
      }
    ],

    mDurPenempatan: [{
        text: 1,
        value: 1
      },
      {
        text: 2,
        value: 2
      },
      {
        text: 3,
        value: 3
      },
      {
        text: 4,
        value: 4
      },
      {
        text: 5,
        value: 5
      },
      {
        text: 6,
        value: 6
      },
      {
        text: 7,
        value: 7
      }
    ],

    mDurPosisi: [{
        text: "Supinasi",
        value: "Supinasi"
      },
      {
        text: "Pronasi",
        value: "Pronasi"
      },
      {
        text: "Litotomi",
        value: "Litotomi"
      },
      {
        text: "Lateral Kanan",
        value: "Lateral Kanan"
      },
      {
        text: "Lateral Kiri",
        value: "Lateral Kiri"
      },
      {
        text: "Sim Kanan",
        value: "Sim Kanan"
      },
      {
        text: "Sim Kiri",
        value: "Sim Kiri"
      },
      {
        text: "Telungkup",
        value: "Telungkup"
      },
      {
        text: "Lain-Lain",
        value: "Lain-Lain"
      }
    ],

    mDurPrepOperasi: [{
        text: "Cuci tangan bedah",
        value: "Cuci tangan bedah"
      },
      {
        text: "Memakai jas operasi",
        value: "Memakai jas operasi"
      },
      {
        text: "Memakai Handzcoen Steril",
        value: "Memakai Handzcoen Steril"
      },
      {
        text: "Memasang linen steril",
        value: "Memasang linen steril"
      }
    ],

    mDurPrepDesinfektan: [{
        text: "Lodine Providone 10 %",
        value: "Lodine Providone 10 %"
      },
      {
        text: "Minosep alcohol 70 %",
        value: "Minosep alcohol 70 %"
      },
      {
        text: "Lain–lain",
        value: "Lain–lain"
      }
    ],

    mDurPrepPenutupLuka: [{
        text: "Betadin",
        value: "Betadin"
      },
      {
        text: "Sufratullr",
        value: "Sufratullr"
      },
      {
        text: "Kassa Sterill",
        value: "Kassa Sterill"
      },
      {
        text: "Steril Drape",
        value: "Steril Drape"
      },
      {
        text: "Opsite",
        value: "Opsite"
      }
    ],

    mDurPrepPenyeteril: [{
        text: "Sterimat",
        value: "Sterimat"
      },
      {
        text: "Oxone",
        value: "Oxone"
      },
      {
        text: "Autoclave",
        value: "Autoclave"
      },
      {
        text: "Ciderex",
        value: "Ciderex"
      }
    ],

    mDurPrepKlasifikasi: [{
        text: "Luka Bersih",
        value: "Luka Bersih"
      },
      {
        text: "Terkontaminas",
        value: "Terkontaminas"
      },
      {
        text: "Infeksi",
        value: "Infeksi"
      }
    ],


    mDurET: [{
        text: "Portex",
        value: "Portex"
      },
      {
        text: "Non Kingking",
        value: "Non Kingking"
      },
      {
        text: "Black",
        value: "Black"
      },
      {
        text: "No. ET",
        value: "No. ET"
      }
    ],

    mDurDiatermi: [{
        text: "Bipolar",
        value: "Bipolar"
      },
      {
        text: "Monopolar",
        value: "Monopolar"
      },
      {
        text: "Harmonic Scaple",
        value: "Harmonic Scaple"
      },
      {
        text: "Memasang Negatif Neutral / Plate",
        value: "Memasang Negatif Neutral / Plate"
      }
    ],

    mDurDiatermiLokasi: [{
        text: "Ekstremitas Atas Kanan",
        value: "Ekstremitas Atas Kanan"
      },
      {
        text: "Ekstremitas Atas Kiri",
        value: "Ekstremitas Atas Kiri"
      },
      {
        text: "Ekstremitas Bawah Kanan",
        value: "Ekstremitas Bawah Kanan"
      },
      {
        text: "Ekstremitas Bawah Kiri",
        value: "Ekstremitas Bawah Kiri"
      }
    ],

    mDurDrain: [{
        text: "Haemovac",
        value: "Haemovac"
      },
      {
        text: "Theoracic",
        value: "Theoracic"
      },
      {
        text: "Jenis",
        value: "Jenis"
      },
      {
        text: "Yeates",
        value: "Yeates"
      },
      {
        text: "Lain-lain",
        value: "Lain-lain"
      }
    ],

    mDurIrigasiLuka: [{
        text: "Sodium Chloride 0,9 %",
        value: "Sodium Chloride 0,9 %"
      },
      {
        text: "Antibiotic Spray",
        value: "Antibiotic Spray"
      },
      {
        text: "Antibiotik",
        value: "Antibiotik"
      },
      {
        text: "Hydrogen Perioxode",
        value: "Hydrogen Perioxode"
      },
      {
        text: "Lain-lain",
        value: "Lain-lain"
      }
    ],

    mDurPemakaianCairan: [{
        text: "Water",
        value: "Water"
      },
      {
        text: "Sodium Chloride 0,9 %",
        value: "Sodium Chloride 0,9 %"
      },
      {
        text: "BSS Solution",
        value: "BSS Solution"
      },
      {
        text: "Lain-lain",
        value: "Lain-lain"
      }
    ],

    mDurBalutan: [{
        text: "Biasa Jenis",
        value: "Biasa Jenis"
      },
      {
        text: "Tekanan / Pressure",
        value: "Tekanan / Pressure"
      },
      {
        text: "Jenis",
        value: "Jenis"
      }
    ],

    mDurSpecimenPemeriksaan: [{
        text: "Histology",
        value: "Histology"
      },
      {
        text: "Cystology",
        value: "Cystology"
      },
      {
        text: "Jumlah Total Jaringan / Cairan",
        value: "Jumlah Total Jaringan / Cairan"
      },
      {
        text: "Jenis dari jaringan",
        value: "Jenis dari jaringan"
      },
      {
        text: "Kultur",
        value: "Kultur"
      },
      {
        text: "Frozen Section",
        value: "Frozen Section"
      },
      {
        text: "Lain-lain",
        value: "Lain-lain"
      }
    ],

    mDurJenisKateter: [
      {
        text: "Kateter Lateks",
        value: "Kateter Lateks"
      },
      {
        text: "Kateter Silicon",
        value: "Kateter Silicon"
      },
      {
        text: "Kondom Kateter",
        value: "Kondom Kateter"
      }
    ],

    mRanapUBSKelengkapan: [
      { value: "Laporan operasi", text: "Laporan operasi" },
      { value: "Laporan anestesi", text: "Laporan anestesi" },
      { value: "Foto Rontgen", text: "Foto Rontgen" },
      { value: "USG", text: "USG" }
    ],


    mAldWarnaKulit: [
      { value: 2, text: "Merah / Normal" },
      { value: 1, text: "Pucat" },
      { value: 0, text: "Sianosis" }
    ],

    mAldAktivitas: [
      { value: 2, text: "Gerak empat anggota tubuh" },
      { value: 1, text: "Gerak dua anggota tubuh" },
      { value: 0, text: "Tidak ada gerak" }
    ],

    mAldPernafasan: [
      { value: 2, text: "Napas dalam, batuk, dan tangis kuat" },
      { value: 1, text: "Napas dangkal dan adekuat" },
      { value: 0, text: "Napas apneu / napas tidak adekuat" }
    ],

    mAldTekananDarah: [
      { value: 2, text: "Berbeda ± 20 mmHg dari Pre OP" },
      { value: 1, text: "Berbeda 20-50 mmHg dari Pre OP" },
      { value: 0, text: "Berbeda ± 50 mmHg dari Pre OP" }
    ],

    mAldKesadaran: [
      { value: 2, text: "Sadar penuh mudah dipanggil" },
      { value: 1, text: "Bangun jika dipanggil" },
      { value: 0, text: "Tidak ada respon" }
    ],

    mAldMualMuntah: [
      { value: 2, text: "Minimal (1-2 X muntah)" },
      { value: 1, text: "Minimal (3-5 X muntah)" },
      { value: 0, text: "Berat (Muntah terus menerus - Pengawasan Khusus)" }
    ],

    mAldPendarahan: [
      { value: 2, text: "Minimal (tidak perlu ganti balut)" },
      { value: 1, text: "Sedang (perlu 1 x ganti balut)" },
      { value: 0, text: "Berat (lebih dari 3 x ganti balut)" }
    ],

    mCRPPosisi: [
      { value: "Semi Fowler", text: "Semi Fowler"},
      { value: "Supine", text: "Supine"},
      { value: "Miring", text: "Miring"},
    ],

    mCRPBeriO2: [
      { value: 1, text: "Nasal Kanul" },
      { value: 2, text: "ET" },
    ],

    mBromageScore: [
      { value: 0, text: "Dapat menggerakkan kaki dengan bebas" },
      { value: 1, text: "Dapat menekuk lutut, meggerakkan kaki, tapi belum bisa mengangkat kaki" },
      { value: 2, text: "Dapat menggerakkan kaki" },
      { value: 3, text: "Tidak dapat menggerakkan kaki sama sekali" }
    ],
    mStwKesadaranScore: [
      { value: 2, text: "Bangun" },
      { value: 1, text: "Respon terhadap rangsangan" },
      { value: 0, text: "Tidak ada respon" }
    ],
    mStwPernafasanScore: [
      { value: 2, text: "Batuk / nangis" },
      { value: 1, text: "Pertahanan jalan nafas" },
      { value: 0, text: "Perlu bantuan nafas" }
    ],
    mStwMotorikScore: [
      { value: 2, text: "Gerak bertujuan" },
      { value: 1, text: "Gerak tidak bertujuan" },
      { value: 0, text: "Tidak gerak" }
    ],
    mRanapRuangTo: [
      { value: "RR", text: "Ruang Ranap" },
      { value: "ICU", text: "ICU" },
      { value: "PL", text: "Pulang" },
      { value: "LN", text: "Lainnya" },
    ],
    mPemeriksaanTubuhJaringan: [
      { value: "Eksisi Percobaan", text: "Eksisi Percobaan"},
      { value: "Biopsi", text: "Biopsi"},
      { value: "Operasi", text: "Operasi"},
      { value: "Kerokan", text: "Kerokan"},
      // { value: "Seksi", text: "Seksi"},
      { value: "Lainnya", text: "Lainnya"},
    ],
    mPemeriksaanTubuhDirendam: [
      { value: "Formalin 10%", text: "Formalin 10%"},
      { value: "Alkohol 50%", text: "Alkohol 50%"},
      { value: "Lainnya", text: "Lainnya"},
    ],
    mGejalaReaksi: [
      {
        text: "Urtikaria",
        value: "Urtikaria"
      },
      {
        text: "Demam",
        value: "Demam"
      },
      {
        text: "Gatal",
        value: "Gatal"
      },
      {
        text: "Takikardi",
        value: "Takikardi"
      },
      {
        text: "Hematuria / Hemoglobinuria**",
        value: "Hematuria / Hemoglobinuria**"
      },
      {
        text: "Nyeri dada",
        value: "Nyeri dada"
      },
      {
        text: "Nyeri kepala",
        value: "Nyeri kepala"
      },
      {
        text: "Syok**",
        value: "Syok**"
      },
      {
        text: "Sesak napas**",
        value: "Sesak napas**"
      }
    ],
    reaksiTransfusiRingan : [
      {
        "text": "Hentikan transfuse / lambatkan tetesan transfuse darah",
        "value": "Hentikan transfuse / lambatkan tetesan transfuse darah"
      }, {
        "text": "Laporkan hasil transfuse ke DPJP dan BDRS",
        "value": "Laporkan hasil transfuse ke DPJP dan BDRS"
      }, {
        "text": "Recheck barcode dengan identitas pasien",
        "value": "Recheck barcode dengan identitas pasien"
      }, {
        "text": "Laksanakan Advis dari DPJP",
        "value": "Laksanakan Advis dari DPJP"
      }
    ],
    reaksiTransfusiSedang: [
      {
        "text": "Hentikan transfuse darah",
        "value": "Hentikan transfuse darah"
      }, {
        "text": "Ganti transfuse set dengan Infus line NaCl 0,9 %",
        "value": "Ganti transfuse set dengan Infus line NaCl 0,9 %"
      }, {
        "text": "Lapor reaksi transfuse ke DPJP Dan BDRS",
        "value": "Lapor reaksi transfuse ke DPJP Dan BDRS"
      }, {
        "text": "Kirim daran beserta transfuse setnya ke BDRS",
        "value": "Kirim daran beserta transfuse setnya ke BDRS"
      }, {
        "text": "Laksanakan Advis dari DPJP",
        "value": "Laksanakan Advis dari DPJP"
      }
    ],
    reaksiTransfusiBerat: [
      {
        "text": "Hentikan transfuse darah",
        "value": "Hentikan transfuse darah"
      }, {
        "text": "Gabti tranfusi set dengan Infus line NaCl 0,9%",
        "value": "Gabti tranfusi set dengan Infus line NaCl 0,9%"
      }, {
        "text": "Pertahankan patensi jalan nafas",
        "value": "Pertahankan patensi jalan nafas"
      }, {
        "text": "Lapor reaksi transfuse ke DPJP dan BDRS",
        "value": "Lapor reaksi transfuse ke DPJP dan BDRS"
      }, {
        "text": "Kirim darah beserta transfuse setnya ke BDRS",
        "value": "Kirim darah beserta transfuse setnya ke BDRS"
      }
    ],

    mSLKIEkspetasi: [
      {
        text: 'Meningkat',
        value: 'Meningkat'
      },
      {
        text: 'Menurun',
        value: 'Menurun'
      },
      {
        text: 'Membaik',
        value: 'Membaik'
      },
    ],
    mSDKIAssesment: [
      {
        text: 'Teratasi',
        value: 'Teratasi'
      },
      {
        text: 'Teratasi Sebagian',
        value: 'Teratasi Sebagian'
      },
      {
        text: 'Tidak Teratasi',
        value: 'Tidak Teratasi'
      },
    ],
    mSDKIPlanning: [{
        text: 'Hentikan Intervensi',
        value: 'H'
      },
      {
        text: 'Lanjutkan Intervensi',
        value: 'LI'
      },
      {
        text: 'Lanjutkan Sebagian Intervensi',
        value: 'LS'
      },
    ],

    bulanTahun: [
      {
        text: 'Bulan',
        value: 'Bulan'
      },
      {
        text: 'Tahun',
        value: 'Tahun'
      }
    ],
    lanjutanMonitoring:[
      {
        text: 'Tidak',
        value: 'N'
      }, {
        text: 'Ya, Lanjutkan',
        value: 'Y'
      }
    ],
    jenisObat: [
      {
        text: 'Rawat Inap',
        value: 'RANAP'
      }, {
        text: 'Rawat Jalan',
        value: 'RAJAL'
      }
    ],
    waktuKajianInterval: [
      {
        text: '15 Menit',
        value: 900
      },
      {
        text: '1 Jam',
        value: 3600
      },
      {
        text: '3 Jam',
        value: 10800
      },
      {
        text: '7 Jam',
        value: 25200
      },
      {
        text: 'Lainnya',
        value: 999999
      },
    ],
    ketWaktuInterval:[
      {
        text: 'Menit',
        value: 60
      },
      {
        text: 'Jam',
        value: 3600
      }
    ],
    "mIntervensiNonFarmakologi": [
      { "text": "Dingin", "value": "Dingin" },
      { "text": "Panas", "value": "Panas" },
      { "text": "Posisi", "value": "Posisi" },
      { "text": "Pijat", "value": "Pijat" },
      { "text": "Musik", "value": "Musik" },
      { "text": "Relaksasi & Pernafasan", "value": "Relaksasi & Pernafasan" }
    ],
    rutePemberian: [
      { "text": "Oral", "value": "Oral" },
      { "text": "IV", "value": "IV" }
    ],
    jenisRestrain: [{
        text: "Farmakologi",
        value: "F"
      },
      {
        text: "Non Farmakologi",
        value: "N"
      },
    ],
    configManfaat: [{
        text: "Bermanfaat",
        value: "Y"
      },
      {
        text: "Tidak Bermanfaat",
        value: "N"
      },
    ],
    lokasiPemasanganRestrain: [
      {
        text: "Pergelangan Tangan Kanan",
        value: "Pergelangan Tangan Kanan"
      },
      {
        text: "Pergelangan Tangan Kiri",
        value: "Pergelangan Tangan Kiri"
      },
      {
        text: "Pergelangan Kaki Kiri",
        value: "Pergelangan Kaki Kiri"
      },
      {
        text: "Pergelangan Kaki Kanan",
        value: "Pergelangan Kaki Kanan"
      },
    ],

    zScore: [
      {
        text: "< -3 SD",
        value: 1,
        desc: "Sangat Kurus",
      },
      {
        text: "-3 s/d < -2 SD",
        value: 2,
        desc: "Kurus",
      },
      {
        text: "-2 s/d 2 SD",
        value: 3,
        desc: "Normal",
      },
      {
        text: "> 2 SD ",
        value: 4,
        desc: "Gemuk",
      },
    ],

    selesaiPerawatan: [
      {
        text: "Pulang",
        value: "Pulang"
      },
      {
        text: "Rujuk",
        value: "Rujuk",
      },
      {
        text: "Atas Permintaan Pasien",
        value: "Atas Permintaan Pasien",
      },
      {
        text: "Meninggal",
        value: "Meninggal",
      },
    ],


    statusLanjutPindahRuang: [
      {
        text: "ICU",
        value: "ICU"
      },
      {
        text: "PICU/NICU",
        value: "NICU"
      },
      {
        text: "Isolasi Airborne",
        value: "AIRBORNE"
      },
      {
        text: "Isolasi Immunosupresi",
        value: "IMUNOSUPRESI"
      },
    ],

    statusLanjutPindahRuangRanap: [
      {
        text: "Masuk ICU",
        value: "ICU"
      },
      {
        text: "Masuk PICU/NICU",
        value: "NICU"
      },
      {
        text: "Masuk Isolasi Airborne",
        value: "AIRBORNE"
      },
      {
        text: "Masuk Isolasi Immunosupresi",
        value: "IMUNOSUPRESI"
      },
      {
        text: "Keluar ICU",
        value: "KICU"
      },
      {
        text: "Keluar PICU/NICU",
        value: "KNICU"
      },
      {
        text: "Keluar Isolasi Airborne",
        value: "KAIRBORNE"
      },
      {
        text: "Keluar Isolasi Immunosupresi",
        value: "KIMUNOSUPRESI"
      },
    ],



    statusLanjutAirborne: [
      {
        text: "SUSPEK TB",
        value: "SUSPEK TB"
      },
      {
        text: "CAMPAK",
        value: "CAMPAK"
      },
      {
        text: "VARICELLA",
        value: "VARICELLA"
      },
      {
        text: "PENYAKIT LAIN",
        value: "PENYAKIT LAIN"
      },
    ],

    statusLanjutImuno: [
      {
        text: "HIV",
        value: "HIV"
      },
      {
        text: "SLE",
        value: "SLE"
      },
      {
        text: "GANGGUAN IMUNOLOGIS LAIN",
        value: "GANGGUAN IMUNOLOGIS LAIN"
      }
    ],


    statusRencanaTindakanPraInduksi: [
      {
        text: "CITO",
        value: "CITO"
      },
      {
        text: "Urgent",
        value: "URGENT"
      },
      {
        text: "Elektif",
        value: "ELEKTIF"
      }
    ],

    statusRiwayatKeluargaAnestesi: [
      {
        text: "Alergi",
        value: "Alergi"
      },
      {
        text: "Jantung",
        value: "Jantung"
      },
      {
        text: "Perokok",
        value: "Perokok"
      },
      {
        text: "Asma",
        value: "Asma"
      },
      {
        text: "Paru",
        value: "Paru"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      },
    ],
    mAsaPraInduksi: [{
        text: "ASA 1  : Seorang pasien normal dan sehat , tidak ada penyakit organ",
        value: 1
      },
      {
        text: "ASA 2  : Seorang pasien dengan penyakit sistemik ringan, atau sedang tanpa gangguan",
        value: 2
      },
      {
        text: "ASA 3  : Seorang pasien dengan penyakit sistemik berat atau dengan gangguan fungsional definitif",
        value: 3
      },
      {
        text: "ASA 4  : Pasien yang memiliki penyakit sistemik berat yang mengancam nyawa seperti iskemia jantung, syok,sepsis dan yang lainnya",
        value: 4
      },
      {
        text: "ASA 5 - Pasien dengan kondisi kritis yang diperkirakan tidak bertahan tanpa pembedahan",
        value: 5
      }
      // {
      //   text: 5,
      //   value: 5
      // },
      // {
      //   text: 6,
      //   value: 6
      // }
    ],
    mStatusMental: [
      {
        text: "Sadar Penuh",
        value: "Sadar Penuh",
      },
      {
        text: "Bingung",
        value: "Bingung",
      },
      {
        text: "Agitasi",
        value: "Agitasi",
      },
      {
        text: "Mengantuk",
        value: "Mengantuk",
      },
      {
        text: "Koma",
        value: "Koma",
      },
    ],

    configVitalSignRanapPraInduksi: [{
      'value': 'aranpio_vs_kepala',
      'label': 'Kepala',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_mata',
      'label': 'Mata',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_hidung',
      'label': 'Hidung',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_rambut',
      'label': 'Rambut',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_bibir',
      'label': 'Bibir',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_gigi',
      'label': 'Gigi Geligi',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_lidah',
      'label': 'Lidah',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_langit',
      'label': 'Langit - Langit',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_leher',
      'label': 'Leher',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_tenggorokan',
      'label': 'Tenggorokan',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_tonsil',
      'label': 'Tonsil',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_dada',
      'label': 'Dada',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_payudara',
      'label': 'Payudara',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_punggung',
      'label': 'Punggung',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_perut',
      'label': 'Perut',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_genital',
      'label': 'Genital',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_anus',
      'label': 'Anus/Dubur',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_lengan_atas',
      'label': 'Lengan Atas',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_lengan_bawah',
      'label': 'Lengan Bawah',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_jari_tangan',
      'label': 'Jari Tangan',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_kuku',
      'label': 'Kuku Tangan',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_sendi_tangan',
      'label': 'Pesendian Tangan',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_tungkai_atas',
      'label': 'Tungkai Atas',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_tungkai_bawah',
      'label': 'Tungkai Bawah',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_jari_kaki',
      'label': 'Jari Kaki',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_kuku_kaki',
      'label': 'Kuku Kaki',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_persendian_kaki',
      'label': 'Persendian Kaki',
      'isOpen': false
    }, {
      'value': 'aranpio_vs_saraf',
      'label': 'Saraf',
      'isOpen': false
    }],

    mJenisRanapNormal: [
      {
        "text": "Obsgyn",
        "value": 1
      }, {
        "text": "Umum",
        "value": 3
      }, {
        "text": "Perawatan Kritis",
        "value": 4
      }
    ],
    sehatTidakSehat: [
      {
        text: "Sehat",
        value: "Sehat"
      },
      {
        text: "Tidak Sehat",
        value: "Tidak Sehat"
      }
    ],
    keteranganFormulir: [
      {
        text: "Perlu Oksigen",
        value: "Perlu Oksigen"
      },
      {
        text: "Masuk Ke Kamar Isolasi",
        value: "Masuk Ke Kamar Isolasi"
      },
      {
        text: "Masuk ICU/PICU/NICU",
        value: "Masuk ICU/PICU/NICU"
      },
      {
        text: "Sudah Pesan Kamar",
        value: "Sudah Pesan Kamar"
      },
      {
        text: "Lainnya",
        value: "Lainnya"
      },
    ],
    yaTidakBelum: [
      {
        text: "Ya",
        value: "Y"
      },
      {
        text: "Tidak",
        value: "N"
      },
      {
        text: "Belum",
        value: "B"
      },
    ],
    kICUTTV: [{
        text: "Nadi >60 atau < 100 x/menit",
        value: "Nadi >60 atau < 100 x/menit"
      },
      {
        text: "Mean arterial pressure > 65 mm/Hg",
        value: "Mean arterial pressure > 65 mm/Hg"
      },
      {
        text: "Frekuensinafas 18-24 x/menit",
        value: "Frekuensinafas 18-24 x/menit"
      },
      {
        text: "Diuresis > 0,5 ml/kg BB/menit",
        value: "Diuresis > 0,5 ml/kg BB/menit"
      },
      {
        text: "Tekanan darah diastolik < 110 mmHg",
        value: "Tekanan darah diastolik < 110 mmHg"
      },
      {
        text: "Pasien sadar / tidak sadar sudah terpasang tracheostomi tube",
        value: "Pasien sadar / tidak sadar sudah terpasang tracheostomi tube"
      }
    ],
    kICUNilaiLab: [{
        text: "Natrium serum 125-150 mEq/L",
        value: "Natrium serum 125-150 mEq/L"
      },
      {
        text: "Kalium serum 3-3,5 mEq/L",
        value: "Kalium serum 3-3,5 mEq/L"
      },
      {
        text: "Glukosa serum 80-180mg/dl",
        value: "Glukosa serum 80-180mg/dl"
      },
      {
        text: "Kalsium serum 2,5-3 mmol/L",
        value: "Kalsium serum 2,5-3 mmol/L"
      },
      {
        text: "Penyakit atau keadaan pasien telah membaik dan cukup stabil sehingga tidak memerlukan terapi atau pemantauan yang intensif lebih lanjut",
        value: "Penyakit atau keadaan pasien telah membaik dan cukup stabil sehingga tidak memerlukan terapi atau pemantauan yang intensif lebih lanjut"
      },
      {
        text: "Secara perkiraan dan perhitungan terapi atau pemantauan intensif tidak bermanfaat atau memberi hasil yang berarti bagi pasien. Apalagi pada waktu itu pasien tidak menggunakan alat bantu mekanis khusus (seperti ventilasi mekanis)",
        value: "Secara perkiraan dan perhitungan terapi atau pemantauan intensif tidak bermanfaat atau memberi hasil yang berarti bagi pasien. Apalagi pada waktu itu pasien tidak menggunakan alat bantu mekanis khusus (seperti ventilasi mekanis)"
      }
    ],
    kNICUKriteriaUmum: [{
        text: "Bila kondisi bayi / anak stabil dan kebutuhan akan monitor serta perawatan NICU / PICU sudah tidak diperlukan lagi",
        value: "Bila kondisi bayi / anak stabil dan kebutuhan akan monitor serta perawatan NICU / PICU sudah tidak diperlukan lagi"
      },
      {
        text: "Bila kondisi fisiologis bayi / anak memburuk dan tidak ada lagi rencana intervensi aktif",
        value: "Bila kondisi fisiologis bayi / anak memburuk dan tidak ada lagi rencana intervensi aktif"
      }
    ],
    kNICUTTV: [{
        text: "Nadi >80 atau <180 x/menit",
        value: "Nadi >80 atau <180 x/menit"
      },
      {
        text: "Frekuensi nafas 40-60 x/menit",
        value: "Frekuensi nafas 40-60 x/menit"
      },
      {
        text: "Penyakit atau keadaan pasien telah membaik dan cukup stabil. Secara perkiraan dan perhitungan terapi atau pemantauan intensif tidak bermanfaat atau tidak memberikan hasil yang berarti bagi pasien.",
        value: "Penyakit atau keadaan pasien telah membaik dan cukup stabil. Secara perkiraan dan perhitungan terapi atau pemantauan intensif tidak bermanfaat atau tidak memberikan hasil yang berarti bagi pasien."
      },
      {
        text: "Pasien atau keluarga menolak untuk dirawat lebih lanjut di NICU / PICU (keluar paksa), yang terlebih dahulu sudah diberi penjelasan dan atas dasar 'informed consent'",
        value: "Pasien atau keluarga menolak untuk dirawat lebih lanjut di NICU / PICU (keluar paksa), yang terlebih dahulu sudah diberi penjelasan dan atas dasar 'informed consent'"
      }
    ],
    kAirborne: [
      {
        text: "Pasien TB Pulmo yang telah mendapat OAT, Klinis membaik dan memungkinkan untuk Rawat Jalan",
        value: "Pasien TB Pulmo yang telah mendapat OAT, Klinis membaik dan memungkinkan untuk Rawat Jalan"
      },
      {
        text: "Terbukti bukan TB pulmo",
        value: "Terbukti bukan TB pulmo"
      },
      {
        text: "ADVIS DPJP",
        value: "ADVIS DPJP"
      },
      {
        text: "Terbukti bukan TB paru/Campak/Varicella",
        value: "Terbukti bukan TB paru/Campak/Varicella"
      }
    ],
    kImunoSupresi: [
      {
        text: "Kondisi pasien stabil atau membaik",
        value: "Kondisi pasien stabil atau membaik"
      },
      {
        text: "ADVIS DPJP",
        value: "ADVIS DPJP"
      }
    ],
    bagianTerendahJanin: [
      {
        text: "Kepala",
        value: "Kepala"
      },
      {
        text: "Bokong",
        value: "Bokong"
      },
      {
        text: "Jari Tangan",
        value: "Jari Tangan"
      },
      {
        text: "Jari Kaki",
        value: "Jari Kaki"
      }, 
      {
        text: "Wajah",
        value: "Wajah"
      },
    ],
    mediaTerpasangObsgyn:[
      {
        text: "Infus",
        value: "Infus"
      }, {
        text: "NGT",
        value: "NGT"
      }, {
        text: "OGT",
        value: "OGT"
      }, {
        text: "Lain-Lain",
        value: "L"
      },
    ],

    "generalAnestesi": [{
        "value": "Spontaneus Respiration",
        "text": "Spontaneus Respiration"
      },
      {
        "value": "Assisted Respiration",
        "text": "Assisted Respiration"
      },
      {
        "value": "Controled Respiration",
        "text": "Controled Respiration"
      }
    ],
    "generalAnestesiDengan": [{
        "value": "Endotracheal Tube",
        "text": "Endotracheal Tube"
      },
      {
        "value": "Laryngeal Mask Airway",
        "text": "Laryngeal Mask Airway"
      },
      {
        "value": "Combitube",
        "text": "Combitube"
      },
      {
        "value": "Lainnya",
        "text": "Lainnya"
      }
    ],
    kelasRawatHakBPJS: [
      {
        text : "Kelas 1",
        value : "1",
      },
      {
        text: "Kelas 2",
        value: "2",
      },
      {
        text: "Kelas 3",
        value: "3",
      },
    ],
    kelasRawatNaikBPJS: [
      {
        text: "VVIP",
        value: "1",
      },
      {
        text: "VIP",
        value: "2",      
      },
      {
        text: "Kelas 1",
        value: "3",
      },
      {
        text: "Kelas 2",
        value: "4",
      },
      {
        text: "Kelas 3",
        value: "5",
      },
      {
        text: "ICCU",
        value: "6",
      },
      {
        text: "ICU",
        value: "7",
      },
      {
        text: "Diatas Kelas 1",
        value: "8",
      },
    ],
    pembiayaanBPJS: [
      {
        text: "Pribadi",
        value: 1,
      },
      {
        text: "Pemberi Kerja",
        value: 2,
      },
      {
        text: "Asuransi Kesehatan Tambahan",
        value: 3,
      },
    ],
    statusTemplatePenunjang: [
      {
        text: "Ya",
        value: "Y",
      },
      {
        text: "Tidak, Dokter Saja",
        value: "N"
      }
    ],
    typeLogistikField: [
      {
        text: "Obat",
        value: "FARMASI"
      },
      {
        text: "Aset",
        value: "ASET"
      },
      {
        text: "Umum",
        value: "UMUM"
      },
    ],
    subTypeLogistikField: [
      {
        text: "Obat",
        value: "FARMASI_OBAT"
      },
      {
        text: "Alat Kesehatan",
        value: "FARMASI_ALKES"
      },
      {
        text: "Infus",
        value: "FARMASI_INFUS"
      },
      {
        text: "Aset",
        value: "ASET"
      },
      {
        text: "Umum",
        value: "UMUM"
      },
    ],
    logistikMRStatus: [
      {
        text: "Semua Status",
        value: "ALL",
        color: "warning",
      },
      { text: "Draft", value: "DRAFT", color: "warning" },
      { text: "Waiting Approval MR", value: "WAITING-APPROVAL-MR", color: "info" },
      { text: "MR Rejected", value: "MR-REJECTED", color: "danger"  },
      { text: "Waiting Create PR", value: "WAITING-CREATE-PR", color: "warning"  },
      { text: "Anggaran Approval", value: "ANGGARAN-APPROVAL", color: "info"  },
      { text: "Anggaran Need Revision", value: "ANGGARAN-NEED-REVISION", color: "warning"},
      { text: "Anggaran Rejected", value: "ANGGARAN-REJECTED", color: "danger"  },

      { text: "PR Approval", value: "PR-APPROVAL", color: "primary"  },
      { text: "PR Rejected", value: "PR-REJECTED", color: "danger"  },
      { text: "PR Finished", value: "PR-FINISHED", color: "success"  }
    ],
    
    logistikPRStatus: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Waiting Create PR", value: "WAITING-CREATE-PR", color: "warning"  },
      { text: "Anggaran Approval", value: "ANGGARAN-APPROVAL", color: "info"  },
      { text: "Anggaran Need Revision", value: "ANGGARAN-NEED-REVISION", color: "warning"},
      { text: "Anggaran Rejected", value: "ANGGARAN-REJECTED", color: "danger"  },
      { text: "PR Approval", value: "PR-APPROVAL", color: "primary"  },
      { text: "PR Rejected", value: "PR-REJECTED", color: "danger"  },
      { text: "PR Finished", value: "PR-FINISHED", color: "success"  }
    ],

    logistikAnggaranStatus: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Anggaran Approval", value: "ANGGARAN-APPROVAL", color: "info"  },
      { text: "Anggaran Need Revision", value: "ANGGARAN-NEED-REVISION", color: "warning"},
      { text: "Anggaran Rejected", value: "ANGGARAN-REJECTED", color: "danger"  },
      { text: "PR Approval", value: "PR-APPROVAL", color: "primary"  },
      { text: "PR Rejected", value: "PR-REJECTED", color: "danger"  },
      { text: "PR Finished", value: "PR-FINISHED", color: "success"  }
    ],

    logistikPersetujuanAnggaran: [
      {
        text: "Setujui",
        value: "APPROVE"
      },
      {
        text: "Reject",
        value: "REJECT"
      },
      {
        text: "Revisi",
        value: "REVISE"
      },
    ],
    logistikPOReqStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Buatkan PO", value: "WAITING-CREATE-PO", color: "info"  },
      { text: "Proses PO", value: "PO-PROCESS", color: "warning"},
      { text: "Selesai PO", value: "PO-FINISHED", color: "danger"  },
      { text: "PO Rejected", value: "PO-REJECTED", color: "danger"  },
    ],

    logistikPOStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Draft", value: "DRAFT", color: "warning"  },
      { text: "Approval PO", value: "PO-APPROVAL", color: "info"},
      { text: "PO Ditolak", value: "PO-REJECTED", color: "danger"  },
      { text: "Selesai PO", value: "PO-FINISHED", color: "success"  },
    ],
    
    logistikPOApprovalStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Approval PO", value: "PO-APPROVAL", color: "info"},
      { text: "PO Ditolak", value: "PO-REJECTED", color: "danger"  },
      { text: "Selesai PO", value: "PO-FINISHED", color: "success"  },
      
    ],

    logistikPODisc : [
      { text: "Rp.", value: "RUPIAH"},
      { text: "%", value: "PERSEN"},
    ],

    logistikDORequest : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Menunggu Pembuatan DO", value: "WAITING-CREATE-DO", color: "warning"},
      { text: "Proses DO", value: "DO-PROCESS", color: "info"  },
      { text: "Selesai Pembuatan DO", value: "DO-FINISHED", color: "success"  },
      { text: "DO Dibatalkan", value: "DO-REJECTED", color: "danger"  },

    ],

    // 'DRAFT','WAITING-APPROVAL-PEMBELIAN','DO-FINISHED'
    logistikDOStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Draft", value: "DRAFT", color: "warning"},
      { text: "Menunggu Approval Pembelian", value: "WAITING-APPROVAL-PEMBELIAN", color: "info"  },
      { text: "Selesai DO", value: "DO-FINISHED", color: "success"  },
    ],

    logistikDOBA : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Draft", value: "DRAFT", color: "warning"},
      { text: "Selesai Berita Acara", value: "DONE", color: "success"  },
    ],

    logistikMutasiStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Draft", value: "DRAFT", color: "warning"},
      { text: "Menunggu Persetujuan", value: "WAITING-FOR-APPROVAL", color: "info"  },
      { text: "Disetujui", value: "APPROVED", color: "success"  },
      { text: "Ditolak", value: "REJECTED", color: "danger"  },
      { text: "Selesai Verifikasi", value: "ACCEPTED", color: "success"  },
      { text: "Selesai Verifikasi Anggaran", value: "ACCEPTED", color: "warning"  },
      { text: "Verified Anggaran", value: "VERIFIED_ANGGARAN", color: "success"  },
    ],

    logistikPenarikanBarangStatus : [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Draft", value: "DRAFT", color: "warning"},
      { text: "Menunggu Verifikasi", value: "WAITING-FOR-VERIFIED", color: "info"  },
      { text: "Diverifikasi", value: "VERIFIED", color: "success"  },
    ],
    logistikPenarikanBarangMutasiStatus: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Menunggu Pembuatan", value: "WAITING", color: "info"  },
      { text: "Proses Mutasi", value: "MUTASI-PROCESS", color: "warning"},
      { text: "Selesai", value: "FINISHED", color: "success"  },
    ],
    logistikGudangStatus: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: "Tersedia", value: "AVAILABLE", color: "info"  },
      { text: "Buffer", value: "BUFFER", color: "warning"},
      { text: "Batas Minimal", value: "MIN", color: "success"  },
      { text: "Berlebih", value: "MORE", color: "success"  },
      { text: "Habis", value: "EMPTY", color: "danger"  },
    ],
    logistikBarangFrom: [
      { text: "Gudang", value: "GUDANG"},
      { text: "Sub Unit", value: "SUB_UNIT"},
    ],
    logistikStatusAnggaran: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: 'Draft', value: 'DRAFT', color: "warning"},
      { text: 'Revise', value: 'REVISED', color: "warning"},
      { text: 'Revise Management', value: 'REVISED-MANAGEMENT', color: "warning"},
      { text: 'Menunggu Approval Anggaran', value: 'WAITING-FOR-APPROVAL-ANGGARAN', color: "info"},
      { text: 'Memnunggu Approval Management', value: 'WAITING-FOR-APPROVAL-MANAGEMENT', color: "primary"},
      { text: 'Selesai', value: 'FINISHED', color: "success"},
    ],
    logistikApprovalAnggaran: [
      { text: 'Revise', value: 'REVISE'},
      { text: 'Approved', value: 'APPROVED'},
    ],
    
    logistikJenisBarang: [
      { text: 'Barang Baru', value: 'NEW'},
      { text: 'Barang Lama', value: 'OLD'},
    ],

    logistikNilaiPersediaan: [
      { text: 'Hitung Nilai Persediaan Dengan PPN', value: 'PPN'},
      { text: 'Hitung Nilai Persediaan Tidak Dengan PPN', value: 'XPPN'},
    ],
    
    asalReturn: [
      {
        value: "PENARIKAN",
        text: "No. Penarikan"
      },
      {
        value: "DO",
        text: "No. Delivery Order"
      },
    ],
    
    logistikStatusReturn: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: 'Draft', value: 'DRAFT', color: "warning"},
      { text: 'Menunggu Approval', value: 'WAITING_FOR_APPROVAL', color: "info"},
      { text: 'Approved', value: 'APPROVED', color: "success"},
      { text: 'Rejected', value: 'REJECTED', color: "danger"},
    ],

    logistikBiayaLain: [
      {text: "Biaya Pengiriman", value: "Biaya Pengiriman"},
      {text: "Biaya Materai", value: "Biaya Materai"}
    ],

    
    logistikStatusPemusnahan: [
      { text: "Semua Status", value: "ALL", color: "warning"},
      { text: 'Draft', value: 'DRAFT', color: "warning"},
      { text: 'Menunggu Approval', value: 'WAITING_FOR_APPROVAL', color: "info"},
      { text: 'Approved', value: 'APPROVED', color: "success"},
      { text: 'Rejected', value: 'REJECTED', color: "danger"},
    ],

    caraPemusnahan: [
      {text: "DiHancurkan", value:"DIHANCURKAN"},
      {text: "DiJual", value:"DIJUAL"}
    ],
    
    searchBPJSBY: [
      {text: "NIK", value:"NIK"},
      {text: "NOMOR KARTU", value:"NOKA"}
    ],
  },
  var: {
    isUmum: 1,
    isAsuransi: 2,
    isBPJS: 3,
  },
  wording: {
    detectUpdate: 'Perubahan data belum di simpan.'
  },
  toolsBasicCK: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ],
  dataShownOptions: [{
      text: '10',
      value: 10
    },
    {
      text: '20',
      value: 20
    },
    {
      text: '30',
      value: 30
    },
  ],
  dataShown: 25
}
export default Config