import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'
// import './composition-api'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import socketio from 'socket.io-client'
import VueHtml2Canvas from 'vue-html2canvas'
Vue.config.productionTip = false

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)

import VuePdfApp from "vue-pdf-app"
import "vue-pdf-app/dist/icons/main.css"
import VueSignature from "vue-signature-pad"

Vue.component("vue-pdf-app", VuePdfApp)
Vue.use(VueSignature)


const SocketInstance = socketio(process.env.VUE_APP_SOCKET_URL || 'http://192.168.1.45:3000', {
  path: process.env.VUE_APP_DEV ? '/nodejs/node' : '/node'
})

// prod sekarang
// const SocketInstance = socketio(process.env.VUE_APP_SOCKET_URL || 'http://192.168.1.45:3000', {
//   path: process.env.VUE_APP_DEV ? '/nodejs' : ''
// })

Vue.use(VueHtml2Canvas)
Vue.prototype.$sockets = SocketInstance

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: null,
    locale: 'de-DE',          // Use German locale (comma as the decimal separator)
    decimalLength: 5,         // Support up to 3 decimal places
    precision:  { min: 0, max: 4 },              // Explicitly set 3 decimals
    // defaultValue: 0,           // Default value for input
    autoDecimalMode: false,    // No auto decimal formatting
    valueAsInteger: false,     // Support decimals
    valueRange: { min: 0 },    // Disallow negative values
    allowNegative: false,      // Disable negative inputs
  },
})

Vue.use(new VueSocketIO({
  debug: process.env.VUE_APP_MODE == 'local',
  connection: SocketInstance
}))

// 3rd party plugins
import '@/libs/extension'
import '@/libs/alias'
import '@/libs/uploader'
import '@/libs/vee-validate'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/vue-fragment'
import '@/libs/vue-moment'

store.dispatch('attempt').then(() => {
  new Vue({
    router,
    store,
    data() {
      return {
        var: { 
          loadingOverlay: false, 
          loadingOverlayText: null
        },
        app: { uploader: {} },
      }
    },
    render: h => h(App)
  }).$mount('#app')
})
